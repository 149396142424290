@if (applicationLoaded) {
<div class="container-fluid p0">
  <div class="row navbar-wrapper" *ngIf="email">
    <nav class="navbar-custom">
      <a class="navbar-brand" href="#"><img src="assets/unikon-logo-fullcolor.svg" class="brand-image" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse nav-content">
        <ul class="navbar-nav mr-auto portal-links">
          <li class="nav-item active portal-link" (click)="GoToPrev()">
            <a class="nav-link"><i class="bi bi-arrow-left-circle"></i></a>
          </li>
          <li class="nav-item active portal-link">
            <a class="nav-link" href="#"><i class="bi bi-house"></i><span>Portaal</span></a>
          </li>
          <li class="nav-item active portal-link settings-link" *ngIf="activeMembership?.roles?.includes('owner')">
            <a class="nav-link" href="#/admin-settings"><i class="bi bi-gear"></i></a>
          </li>
        </ul>
        <div class="active-user">
          <div class="active-user-content">
            <span>Welkom: <strong>{{activeUser?.name ?? "Gebruiker"}}</strong></span>
            <button class="btn btn-primary sign-out" (click)="signOut()"><i class="bi bi-box-arrow-right"></i></button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
<router-outlet></router-outlet>
<div *ngIf="toastMessage" class="toast" role="alert" aria-live="assertive" aria-atomic="true" [ngClass]="{'warning-toast': toastKind == 'Error'}">
  <div class="toast-header">
    <div>
      <img src="assets/Unicon-32.png" class="rounded mr-2" alt="UNIKON-LOGO">
      <strong class="mr-auto">Service-Applicatie</strong>
    </div>
    <small class="text-muted">Nu</small>
  </div>
  <div class="toast-body">
    {{toastMessage}}
  </div>
</div>
<div class="overlay-maintenance" *ngIf="(!application || application?.isInMaintenance) && applicationLoaded">
  <div class="maintenance-pop-up">
      <div class="header-maintenance">
          <img src="assets/Unicon-32.png" />
          <h3>Sorry! Momenteel is deze tool niet beschikbaar</h3>
      </div>
      <h5>Onderhoud is nodig om de tool toekomstbestendig te houden.</h5>
      <span class="caption" *ngIf="!application?.maintenanceMessage">Probeer het later nog eens. Bij vragen neem contact op met de beheerder</span>
      <div *ngIf="application?.maintenanceMessage" class="extra-message">
          <span class="extra-header">Aanvullend bericht:</span>
          <span>{{application?.maintenanceMessage}}</span>
      </div>
  </div>
  <div class="context-icon">
      <i class="bi bi-tools"></i>
  </div>
</div>
<footer *ngIf="email">
  <span>UNIKON - Service Application - {{activeVersion}}</span>
</footer>
@if(showDeveloperWarning){
  <div class="developer-warning">
    <div class="alert alert-danger" role="alert">
      <i class="bi bi-exclamation-triangle"></i> <strong> LET OP:</strong> MAINTAINANCE WAARSCHUWING INGESCHAKELD <i class="bi bi-exclamation-triangle"></i>
    </div>
  </div>
}
}@else{
  <div class="loader-center">
    Laden... Een moment geduld alstublieft.
  </div>
}
