<div class="modal-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <span class="float-end clickable" (click)="closeModal()"><i class="bi bi-x-lg"></i></span>
                <h5 class="text-center"><i class="bi bi-warning"></i> Verwijderen</h5>
                <p class="text-center">
                    Weet u zeker dat u {{entryType}} <b>{{entryIdentifier}}</b> wilt verwijderen?
                </p>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-danger mx-1" (click)="callback(entry) && closeModal()">Ja</button>
                <button class="btn btn-secondary mx-1" (click)="closeModal()">Annuleren</button>
            </div>
        </div>
    </div>
    
</div>