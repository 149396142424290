import { Injectable } from '@angular/core';
import { ID, Models, Query, RealtimeResponseEvent } from 'appwrite';
import { BehaviorSubject } from 'rxjs';
import { Api } from 'src/app/helpers/api';
import { LoggingService } from '../logging/logging.service';
import { DB, DBConfig, DBT } from 'src/app/models/db/models';

type repositoryEntry<COLL extends keyof DBT["default"]> = {
  coll: COLL,
  documents: BehaviorSubject<(DBT["default"][COLL] & Models.Document)[]|[]>
}

const DATABASE = 'default';

@Injectable({
  providedIn: 'root'
})
// The Repository is responsible for retrieving, storing and
// keeping up to date ALL documents without filtering.
// Realtime triggers have effect on the repository
export class RepositoryService {

  repositoryVault: repositoryEntry<any>[];
  realtimeSub: any;

  constructor(private logger: LoggingService) { 
    this.repositoryVault = []

    // Create Realtime connection.
    // Note: Recreating causes issues so we subscribe to everything from the start.
    this.realtimeSub = Api.client.subscribe('documents', res => {
      this.handleRealtimeUpdate(res)
    });
    this.logger.debug("Realtime Set up", 'Repository')
    
  }

  onInit() {
  }

  onDestroy() {
    // Destroy Realtime Sub.
    this.realtimeSub()
  }

  async getSubscription<COLL extends keyof DBT["default"]>(collKey: COLL) {
    // Singleton function

    // Fetch collection ID, used internally.
    let coll = DB['default']['collections'][collKey]

    let rep = this.repositoryVault.filter(e => e.coll == coll)
    if(rep.length > 0) {
      this.logger.debug(coll + " Found, no need for fetching", 'Repository')
      return rep[0].documents
    } else {
      // Until we find something better, manually set type:
      this.logger.debug('Fetching from Remote: ' +coll, 'Repository')
      // Prevent double-fetching: First push sub, then fetch and update.
      let sub = new BehaviorSubject<(DBT["default"][COLL] & Models.Document)[]|[]>([]);
      this.repositoryVault.push({coll: coll, documents: sub})

      let allDocs = await this.retrieveFromDB<COLL>(collKey)
      sub.next(allDocs)
      return sub
    }

  }

  handleRealtimeUpdate(res: RealtimeResponseEvent<any>) {
    let payload = res.payload
    // First Isolate the colloection
    let cString = res.channels.find((s) => s.includes("databases.default.collections."))
    let collection = cString?.split('.')[3]
    if(!collection){
      this.logger.error("Could not determine collection", 'Repository')
    }
    // Handle Update / Create / Delete
    if(res.events.includes("databases.*.collections.*.documents.*.update")) {
      this.logger.debug("RealTime: Update", 'Repository')
      // Update
      let rep = this.repositoryVault.find(e => e.coll == collection)
      if (!rep) return
      let docs = rep?.documents.value

      const idx = docs.findIndex(e => e.$id == payload.$id);
      if (idx >= 0) {
          docs[idx] = {...docs[idx], ...payload}; //Overwrite & Preserve Relations
      } else {
        this.logger.error("Error: Could not find idx of To Update doc")
      }
      rep.documents.next(docs);
      this.logger.debug("RealTime: Updated Document", 'Repository')
      return

    } else if (res.events.includes("databases.*.collections.*.documents.*.create")) {
      //Create
      this.logger.debug("RealTime: Create for " + collection, 'Repository')

      let rep = this.repositoryVault.find(e => e.coll == collection)
      if (!rep) {
        this.logger.debugObj("RealTime: Could not find initialized collection: " + collection, this.repositoryVault, 'Repository')
        return
      } 
      let docs = rep?.documents.value
      //@ts-ignore TODO FIX TYPE
      docs.push(payload)

      rep.documents.next(docs);
      this.logger.debug("RealTime: Created Entry for " + collection, 'Repository')
      return

    } else if (res.events.includes("databases.*.collections.*.documents.*.delete")) {
      //Delete
      this.logger.debug("RealTime: Delete for " + collection, 'Repository')
      let rep = this.repositoryVault.find(e => e.coll == collection)
      if (!rep) return
      let docs = rep?.documents.value

      const idx = docs.findIndex(e => e.$id == payload.$id);
      if (idx >= 0) {
        docs.splice(idx,1); //Remove from array
      } else {
        this.logger.error("Error: Could not find idx of To Delete doc")
      }
      rep.documents.next(docs);
      this.logger.debug("RealTime: Deleted Entry for " + collection, 'Repository')
      return

    } else {
      this.logger.debugObj("Unkown Event:" + JSON.stringify(res), 'Repository')
    }

  }

  retrieveFromDB = async <COLL extends keyof DBT["default"]>(coll: COLL) => {
    let resultLimit = 50;
    let resultCount = 50
    let allDocuments: (DBT["default"][COLL] & Models.Document)[] = [];
    let lastId: string | undefined = undefined;
    let requestDocuments: Models.DocumentList<DBT["default"][COLL] & Models.Document>;

    while (resultCount == resultLimit) {
      if(lastId){
        requestDocuments = await Api.database.listDocuments<DBT["default"][COLL] & Models.Document>(
          DATABASE,
          DB["default"].collections[coll],
          [Query.limit(resultLimit), Query.cursorAfter(lastId)]
        );
      } else {
        requestDocuments = await Api.database.listDocuments<DBT["default"][COLL] & Models.Document>(
          DATABASE,
          DB["default"].collections[coll],
          [Query.limit(resultLimit)]
        );
      }

      lastId = requestDocuments?.documents[requestDocuments.documents.length - 1]?.$id;
      allDocuments.push(...requestDocuments.documents);
      resultCount = requestDocuments.documents.length;
    }

    return allDocuments;
  }

  async update(coll: keyof DBConfig['default']['collections'], entry: any) {
    // Deep copy entry
    let dcEntry = JSON.parse(JSON.stringify(entry));

    if(!dcEntry.$id) {
      this.logger.error("Unable to Update: Entry has no ID")
      return
    }
    let docId = dcEntry.$id
    let doc = this.prepareDocumentForAppwrite(dcEntry)

    this.logger.debugObj("Update Entry:", dcEntry, 'Repository')

    return Api.database.updateDocument(
      DATABASE,
      DB["default"].collections[coll],
      docId,
      doc
    );
  }

  async create(coll: keyof DBConfig['default']['collections'], entry: any) {
    // Deep copy entry
    let dcEntry = JSON.parse(JSON.stringify(entry));

    let doc = this.prepareDocumentForAppwrite(dcEntry)

    this.logger.debugObj("Create Entry:", dcEntry, 'Repository')

    return Api.database.createDocument(
      DATABASE,
      DB["default"].collections[coll], 
      ID.unique(), 
      doc
    );
   
  }

  async delete(coll: keyof DBConfig['default']['collections'], entry: any) {
    if(!entry.$id) {
      this.logger.error("Unable to Delete: Entry has no ID", 'Repository')
      return
    }

    return Api.database.deleteDocument(
      DATABASE, 
      DB["default"].collections[coll], 
      entry.$id
    );
  }

  private prepareDocumentForAppwrite(entry: any) {

    Object.keys(entry).forEach(key => {
      if(key.indexOf('$') != -1) {
        // Appwrite-related key, remove.
        delete entry[key]
      }
      
      // Handle many-to-one and many-to-many relationships
      // if(Array.isArray(entry[key])) {

      // }
      // // Hande one-to-one and one-to-many relationships
      // if(typeof entry[key] === 'object') {
      //   // Recursively call & fix
      //   entry[key] = this.prepareDocumentForAppwrite(entry[key])
      // }
    })
    return entry
  }

}
