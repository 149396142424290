import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppwriteException } from 'appwrite';
import { Subscription } from 'rxjs';
import { Company } from 'src/app/models/company';
import { Machine } from 'src/app/models/machine';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { ContextService } from 'src/app/services/context/context.service';
import { MachineService } from 'src/app/services/machine/machine.service';

@Component({
  selector: 'app-asset-management-machine-edit',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './asset-management-machine-edit.component.html',
  styleUrl: './asset-management-machine-edit.component.scss'
})
export class AssetManagementMachineEditComponent {

  @Input() serviceRequest?: ServiceRequest;
  @Output() createCompany = new EventEmitter();

  machine?: Machine;
  companies?: Company[];

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(private communicationService: CommunicationService, public contextService: ContextService, private machineService: MachineService){
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit() {
    // Subscribe on the active set of companies
    this.subs.push(
      this.communicationService.activeCompanies?.subscribe(companies => { 
        this.companies = companies.sort((a,b) => (a!.companyName > b!.companyName) ? 1 : ((b!.companyName > a!.companyName) ? -1 : 0));
      })
    )

    this.subs.push(
      this.communicationService.activeMachine?.subscribe(activeMachine => {
        this.machine = activeMachine;
      })
    )
  }
  /**
   * This method will trigger the create new company emitter
   */
  createNewCompany(){
    this.createCompany?.emit();
  }

  /**
   * This method will update the RelatedTo in the context of the Machine
   */
  relatedCompanyChanged(){
    if(!this.machine){
      return;
    }
    var relatedCompany = this.companies?.filter(c => c.$id == this.machine?.relatedCompanyId) ?? []; 
    this.machine.relatedTo =  relatedCompany?.length > 0 ? relatedCompany[0].companyName : undefined;
  }

  /**
   * This function will close this edit window
   */
  close(){
    this.communicationService.activeMachine.next(undefined);
  }

  /**
   * This function will save the changes that have been made to the database and close the window
   */
  updateMachine(){
    if(!this.machine){
      return;
    }

    if(this.machine.$id){
      this.machineService.updateMachine(this.machine).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het updaten van de machine ${this.machine?.machineId} is gelukt!`);
  
        // Close window
        this.communicationService.activeMachine.next(undefined);
      }).catch((e: AppwriteException) => {
        this.communicationService.activeToastKind.next("Error");
        if(e.code == 409){
          this.communicationService.activeToastMessage.next(`Machinenummer: ${this.machine?.machineId} moet uniek zijn en is reeds in gebruik!`);
        } else {
          this.communicationService.activeToastMessage.next(`Het updaten van machine ${this.machine?.machineId} is mislukt!`);
        }
      });
    } else {
      this.machineService.addMachine(this.machine).then((m) => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het aanmaken van de machine ${this.machine?.machineId} is gelukt!`);
  
        //[0] Add company as reference
        var relatedCompany = this.companies?.filter(c => c.$id == this.machine?.relatedCompanyId) ?? [];
        if(relatedCompany.length > 0){
          (m as Machine).company = relatedCompany[0];
        }

        //[1] Add as related machine to the ServiceRequest if present
        if(this.serviceRequest){
          this.serviceRequest.referencedMachine = m as Machine;
          this.serviceRequest.referencedMachineId = m.$id;
          this.serviceRequest.machineId = (m as Machine).machineId;
        }

        //[2] Store in active set of machines
        var machines = this.communicationService.activeMachines?.getValue();
        var createdMachine = m as Machine;

        // Attach Company
        createdMachine.company = this.machine?.company;
        machines?.push(createdMachine);
        this.communicationService.activeMachines?.next(machines);

        //[3] Close window
        this.communicationService.activeMachine.next(undefined);
      }).catch((e: AppwriteException) => {
        this.communicationService.activeToastKind.next("Error");
        if(e.code == 409){
          this.communicationService.activeToastMessage.next(`Machinenummer: ${this.machine?.machineId} moet uniek zijn en is reeds in gebruik!`);
        } else {
          this.communicationService.activeToastMessage.next(`Het aanmaken van machine ${this.machine?.machineId} is mislukt!`);
        }
      });
    }
  }
}
