import { Component } from '@angular/core';
import { VersionService } from './services/version/version.service';
import { AuthService } from './services/auth/auth.service';
import { UserPreferences } from './models/services/user-preferences';
import { Models } from 'appwrite';
import { CommunicationService } from './services/communication/communication.service';
import { Application } from './models/application';
import { PermissionService } from './services/permission/permission.service';
import { ApplicationService } from './services/application/application.service';
import { VehicleService } from './services/vehicle/vehicle.service';
import { MechanicService } from './services/mechanic/mechanic.service';
import { ServiceRequestService } from './services/service-request/service-request.service';
import { CompanyService } from './services/company/company.service';
import { Router } from '@angular/router';
import { ToolService } from './services/tool/tool.service';
import { MachineService } from './services/machine/machine.service';
import { Subscription } from 'rxjs';
import { ServiceContractService } from './services/service-contract/service-contract.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  activeVersion?: string;
  email?: string;
  activeUser?: Partial<Models.User<UserPreferences>>;
  application?: Application;
  applicationLoaded?: boolean;
  defaultTeam: string = "UNIKON"
  toastMessage?: string;
  toastKind?: string;
  activeMembership?: Models.Membership
  showDeveloperWarning: boolean = false;

  userInitComplete: boolean = false;

  subs: Subscription[] = []

  constructor(private serviceContractService: ServiceContractService, private machineService: MachineService, private applicationService: ApplicationService, private router: Router, private companyService: CompanyService, private serviceRequestService: ServiceRequestService, private mechanicService: MechanicService, private vehicleService: VehicleService, private versionSerivce: VersionService, private permissionService: PermissionService, private authService: AuthService, private communicationService: CommunicationService, private toolService: ToolService){
  }

  async ngOnInit() {
    // Subscripe on Toast Message for if present
    this.communicationService.activeToastMessage.subscribe(message => {
      this.toastMessage = message;
      this.toastKind = this.communicationService.activeToastKind.getValue();

      // Reset the message after 5 seconds
      if(message){
        setTimeout(() => {
          this.toastMessage = undefined;
        }, 5000);
      }
    })
    
    // Get the application context from the database
    await this.applicationService.getApplicationContext("6659a18a000732b40dfb").then(application => {
      
      this.application = application;

      // Disable inMaintanance Warning for Jip
      if(this.activeUser?.$id == "66599e52aae933617b62" && this.application?.isInMaintenance){
        this.application.isInMaintenance = false;
        this.showDeveloperWarning = true;
      }

      this.communicationService.application.next(application);

    }).catch(() => {
      // Show App if unable to connect to DB
      this.applicationLoaded = true;
    });

    // Todo: All subscribes must still be unsubscribed
    
    // Get active version
    this.activeVersion = this.versionSerivce.activeVersion;

    // We must await answer for Authservice
    await this.authService.checkUserState()

    // We subscribe to user State, if the user is logged in, we fetch the documents required.
    // This sub need not be unsubscribed.
    this.authService.user.subscribe(async user => {
      this.activeUser = user;
      this.email = user.email;

      if (!user.$id) {
        console.log("Not logged in")
        this.applicationLoaded = true;
        // Re-init when user logs out
        this.userInitComplete = false;
        // Todo: Remove subs.
        return
      }
      console.log("Logged in")

      // Disable inMaintanance Warning for Jip
      if(this.activeUser?.$id == "66599e52aae933617b62" && this.application?.isInMaintenance){
        this.application.isInMaintenance = false;
        this.showDeveloperWarning = true;
      }
      
      if (this.userInitComplete) {
        console.log("Init previously completed, returning")
        return
      }
      console.log("Setting up user load")
      // Only run the init subscriptions once.
      this.userInitComplete = true

      // Setup Permissions
      await this.permissionService.buildPermissions()
      this.activeMembership = this.permissionService.currentMembership

      // Show the app after permissionmatrix is ready and while we still load non-critical documents in the background.
      this.applicationLoaded = true;

      // Initialize CommunicationContainer
      await this.initialize();

          // Retrieve Vehicles
          this.vehicleService.getVehiclesAndUnavailabilities().then(vehicles => {
            this.communicationService.activeVehicles.next(vehicles);

              // Retrieve Mechanics
              this.mechanicService.getMechanicsAndUnavailabilities().then(mechanics => {
                this.communicationService.activeMechanics.next(mechanics);

                // Find permanent vehicles
                if(mechanics){
                  mechanics.forEach(m => {
                    m.permanentVehicles = [];
                    m.permanentVehicleIds?.split(" ").forEach(v => {
                      var vehicle = vehicles?.filter(vehicle => vehicle.$id == v);

                      if(vehicle?.length > 0){
                        m.permanentVehicles?.push(vehicle[0]);
                      }
                    });
                  });
                }
              });
          });

          this.toolService.getTools().then(tools => {
            this.communicationService.activeTools.next(tools);
          });


      //  });
      
    });
  }

  private async initialize(){
    this.communicationService.loadingCompanies = true;
    this.communicationService.loadingMachines = true;

    // Retrieve Machines
    await this.machineService.getMachines().then(machines => {
      this.communicationService.activeMachines.next(machines);
      this.communicationService.loadingMachines = false;
      return Promise.resolve();
    });

    // Retrieve ServiceRequests
    await this.serviceRequestService.getServiceRequestsAndWindows().then(serviceRequests => {
      let machines = this.communicationService.activeMachines.getValue();
      serviceRequests?.forEach(serviceRequest => {

        // Retrieve attached Machine
        if(serviceRequest.referencedMachineId){
          let referencedMachine = machines?.filter(m => m.$id == serviceRequest.referencedMachineId);

          if(referencedMachine?.length > 0){
            serviceRequest.referencedMachine = referencedMachine[0];
            serviceRequest.machineId = referencedMachine[0].machineId;
          }
        }
      });

      this.communicationService.activeServiceRequests.next(serviceRequests);
      return Promise.resolve();
    });

    // Retrieve Companies
    await this.companyService.getCompanies().then(companies => {
      this.communicationService.activeCompanies.next(companies);

      // Attach correct company to a ServiceRequest
      let machines = this.communicationService.activeMachines?.getValue();

      machines?.forEach(machine => {
        // Retrieve attached Company
        if(machine.relatedCompanyId){
          let referencedCompany = companies?.filter(m => m.$id == machine.relatedCompanyId);

          if(referencedCompany?.length > 0){
            machine.company = referencedCompany[0];
          }
        }
      });

      this.communicationService.loadingCompanies = false;
      return Promise.resolve();
    });

    await this.serviceContractService.getServiceContracts().then(async serviceContracts => {
      this.communicationService.activeServiceContracts.next(serviceContracts);
    });
  }

  /**
   * This method will trigger the SignOut action
   */
  signOut(){
    this.authService.logout().then(() => {
      // Reset Communication Service
      this.communicationService.activeCompanies.next([]);
      this.communicationService.activeMechanics.next([]);
      this.communicationService.activeServiceRequests.next([]);
      this.communicationService.activeVehicles.next([]);
      this.communicationService.activeWritePermissions.next([]);
      this.communicationService.activeTools.next([]);
      this.communicationService.activeTeamId.next(undefined);
      this.communicationService.activeMechanic.next(undefined);
      this.communicationService.activeVehicle.next(undefined);
      this.communicationService.activeServiceRequest.next(undefined);
      this.communicationService.activeTool.next(undefined);
    }).then(() => {
      this.router.navigate(['./sign-in'])
    })
  }

  GoToPrev(){
    history.back();
  }
}
