import { Component, ElementRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DBT } from 'src/app/models/db/models';
import { ServiceRequest } from 'src/app/models/service-request';
import { Models } from 'appwrite';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { Base } from 'src/app/components/base/base.component';

@Component({
  selector: 'app-work-preparation-dashboard',
  templateUrl: './work-preparation-dashboard.component.html',
  styleUrls: ['./work-preparation-dashboard.component.scss']
})
export class WorkPreparationDashboardComponent extends Base {

  activeWVBTask?: DBT['default']['WVBTask']&Models.Document;
  activeServiceRequest?: ServiceRequest;

  // View Related
  showAllWVBTasksModule = true
  showServiceRequestModule = true

  showAllWVBTasksKey: string = "SHOWALLWVBTASKS_KEY";
  showServiceRequestKey: string = "SHOWWVBSERVICEREQUESTS_KEY";

  constructor(private communicationService: CommunicationService, element: ElementRef) { 
    super(element)
    this.showAllWVBTasksModule = (localStorage.getItem(this.showAllWVBTasksKey) ?? "true") == "true";
    this.showServiceRequestModule = (localStorage.getItem(this.showServiceRequestKey) ?? "true") == "true";
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.currentWVBTask.subscribe(t => {
        this.activeWVBTask = t

        var serviceRequests = this.communicationService.activeServiceRequests?.getValue().filter(s => s.$id == t?.serviceRequestId);

        if(serviceRequests.length > 0){
          this.activeServiceRequest = serviceRequests[0];
        }
      })
    )
  }

  toggleAllWVBTasksComponent(setState: boolean){
    this.showAllWVBTasksModule = setState;
    localStorage.setItem(this.showAllWVBTasksKey, JSON.stringify(setState));
  }

  toggleServiceRequestsListComponent(setState: boolean){
    this.showServiceRequestModule = setState;
    localStorage.setItem(this.showServiceRequestKey, JSON.stringify(setState));
  }

}
