import { Component, ElementRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

@Component({
    selector: 'base',
    templateUrl: './base-table.component.html',
    styleUrls: ['./base-table.component.scss']
})

export class Base {
    // The Subscriptions to Unsub on destroy
    subs: Subscription[] = [];

    constructor(private el: ElementRef) { }

    addSub(s: BehaviorSubject<any>, fun?: CallableFunction) {
        this.subs.push(s.subscribe(activeServiceRequestList => {
        // 3: Handle updates
            if (!activeServiceRequestList) {
                return;
            }

            fun ? fun() : '';
            })
        )
    }

    /**
     * Default Ondestroy considering Subscriptions
     */
    ngOnDestroy(): void {
        this.subs.forEach(sub => {
            sub.unsubscribe()
        })
    }
}