<div class="row margin-top" (click)="onClickLostFocus()">
    <div class="div card">
        <div class="card-body">
            <div class="row">
                <h4 class="col card-title">Overzichtslijst Serviceverzoeken</h4>
                <div class="filters">
                    <div class="col-6 search">
                        <div class="filter-selection">
                            <i class="bi bi-funnel"></i>
                            <p-multiSelect 
                            [options]="allStatusses" 
                            [(ngModel)]="selectedStatusses" 
                            optionLabel="name" 
                            (onChange)="viewFilter()"
                            placeholder="Selecteer Statussen" />
                        </div>
                        <i class="bi bi-search" data-toggle="tooltip" data-placement="top" title="Zoeken op: Naam, status, merk..."></i>
                        <input [(ngModel)]="searchInput" (ngModelChange)="viewFilter()" class="form-control" type="text" placeholder="Zoeken...">
                    </div>
                </div>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        @if (_displayedCols.indexOf('Name') > -1) {
                            <th style="width: 26%" class="sortable" scope="col" (click)="viewSort('name', 'string')">Naam
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'name' && sortedAscending"></i>
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'name' && !sortedAscending"></i>
                            </th>
                        }
                        @if (_displayedCols.indexOf('Type') > -1) {
                            <th style="width: 12%" class="sortable" scope="col" (click)="viewSort('serviceRequest.type', 'string')">Type
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'serviceRequest.type' && sortedAscending"></i>
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'serviceRequest.type' && !sortedAscending"></i>
                            </th>
                        }
                        @if (_displayedCols.indexOf('StartTime') > -1) {
                            <th style="width: 16%" class="sortable" scope="col" (click)="viewSort('serviceRequest.startTime', 'Date')">Startdatum
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'serviceRequest.startTime' && sortedAscending"></i>
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'serviceRequest.startTime' && !sortedAscending"></i>
                            </th>
                        }
                        @if (_displayedCols.indexOf('Company') > -1) {
                            <th style="width: 23%" class="sortable" scope="col" (click)="viewSort('company.companyName', 'string')">Klant
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'company.companyName' && sortedAscending"></i>
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'company.companyName' && !sortedAscending"></i>
                            </th>
                        }
                        @if (_displayedCols.indexOf('Status') > -1) {
                            <th style="width: 8%" class="sortable" scope="col" (click)="viewSort('serviceRequest.status', 'string')">Status
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'serviceRequest.status' && sortedAscending"></i>
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'serviceRequest.status' && !sortedAscending"></i>
                            </th>
                        }
                        @if (_displayedCols.indexOf('WVBTasks') > -1) {
                            <th style="width: 16%" scope="col">WVB Taken</th>
                        }
                        @if (_displayedCols.indexOf('Actions') > -1) {
                            <th style="width: 15%" scope="col">Acties</th>
                        }
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let vo of currentView; index as i" [ngClass]="{'active-service-request': this.communicationService.currentServiceRequest.value?.$id == vo.serviceRequest.$id}">
                        @if (_displayedCols.indexOf('Name') > -1) {
                            <td>
                                <div class="service-request-details">
                                    <strong>
                                        <span class="detail"><i class="bi bi-card-text"></i> {{vo.serviceRequest.orderId || 'Onbekend'}} - <i class="bi bi-boxes"></i> {{vo.referencedMachine?.machineId}}</span>
                                    </strong>
                                    <span class="detail">{{vo.serviceRequest.name}}</span>
                                </div>
                            </td>
                        }
                        @if (_displayedCols.indexOf('Type') > -1) {
                            <td>{{vo.serviceRequest.type}}</td>
                        }
                        @if (_displayedCols.indexOf('StartTime') > -1) {
                            <td>{{vo.serviceRequest.startTime | date: 'dd-MM-yy - HH:mm'}}</td>
                        }
                        @if (_displayedCols.indexOf('Company') > -1) {
                            <td>
                                @if(communicationService.loadingMachines || communicationService.loadingCompanies){
                                    {{'Laden...'}}
                                } @else {
                                    {{vo.company?.companyName ?? 'Onbekend'}}
                                }
                            </td>
                        }
                        @if (_displayedCols.indexOf('Status') > -1) {
                            <td class="status-line">
                                <div class="status-badge-section">
                                    <span class="badge badge-primary" (click)="onStatusExpanderClick(vo.serviceRequest.$id)" [ngStyle]="{'background-color': getStatusColor(vo.serviceRequest.status)}">
                                        <i class="{{getStatusIcon(vo.serviceRequest.status)}}"></i>
                                        {{vo.serviceRequest.status}}
                                    </span>
                                    <div (click)="onStatusExpanderClick(vo.serviceRequest.$id)" *ngIf="!isViewer">
                                        <i class="bi bi-caret-down-fill"></i>
                                    </div>
                                    <div class="other-status" *ngIf="communicationService.openStatusServiceRequestId == vo.serviceRequest.$id && communicationService.openStatusServiceRequestId && !isViewer">
                                        <div class="row" *ngFor="let statusItem of this.serviceRequestStatus">
                                            <div class="col">
                                                <span (click)="statusSelectionChanged(vo, statusItem.value)" class="badge badge-primary" [ngStyle]="{'background-color': getStatusColor(statusItem.value)}">
                                                    <i class="{{getStatusIcon(statusItem.value)}}"></i>
                                                    {{statusItem.description}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        }
                        @if (_displayedCols.indexOf('WVBTasks') > -1) {
                            <td>{{vo.nrWVBTasks || "Geen WVB"}}</td>
                        }
                        @if (_displayedCols.indexOf('Actions') > -1) {
                            <td>
                                <div class="service-request-actions">
                                    <button class="btn btn-primary action-button first-button" id="edit{{i}}" (click)="jumpToWVB(vo)">
                                        <i class="bi bi-list-check"></i>
                                    </button>
                                    @if (_delBtnsAvailable) {
                                        <!-- Edit buttons only used for Service Request -->
                                        <button class="btn btn-primary action-button ms-1" id="edit{{i}}" (click)="editServiceRequest(vo)">
                                            <i class="bi bi-pencil"></i>
                                        </button>
                                        <!-- Remove -->
                                        <button *ngIf="!isViewer" class="btn btn-danger remove-button" id="remove{{i}}" (click)="removeServiceRequest(vo, i, true)">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                        <button *ngIf="!isViewer" class="btn btn-danger remove-confirmation" id="confirm-remove{{i}}" (click)="removeServiceRequest(vo, i, false)">Bevestig</button>
                                        <button *ngIf="!isViewer" class="btn btn-primary action-button cancel-remove" id="cancel-remove{{i}}" (click)="cancelRemoval(i)"><i class="bi bi-x-circle"></i></button>
                                    } @else {
                                        <button class="btn btn-primary action-button ms-1" id="edit{{i}}" (click)="jumpToPlanning(vo)">
                                            <i class="bi bi-calendar-week"></i>
                                        </button>
                                    }
                                </div>
                            </td>
                        }

                    </tr>
                </tbody>
            </table>
            <div class="table-footer">
                <div class="no-service-requests" *ngIf="filteredViewObjects.value.length">
                    <div *ngIf="loadingTable" class="loading-section">
                        <span class="loader"></span>
                        <span class="loader-text">Serviceverzoeken worden geladen...</span>
                    </div>
                </div>
                
                <div class="table-footer-actions" [ngClass]="{'align-right': filteredViewObjects.value.length == 0 || !allViewObjects}">
                    <nav aria-label="Page navigation" *ngIf="filteredViewObjects?.value?.length ?? 0 > 0">
                        <ul class="pagination">
                            @if (pageNumbers.includes(activePage-1)) {
                                <li class="page-item" (click)="viewChangePage(activePage = activePage - 1)">
                                    <a class="page-link" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                            }
                            @for (i of pageNumbers; track i) {
                                <li class="page-item" (click)="viewChangePage(i)">
                                    <a class="page-link active" *ngIf="i == activePage">{{i + 1}}</a>
                                    <a class="page-link" *ngIf="i != activePage">{{i + 1}}</a>
                                </li>
                            }
                            @if (pageNumbers.includes(activePage+1)) {
                            <li class="page-item" (click)="viewChangePage(activePage + 1)">
                                <a class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                            }
                        </ul>
                        <div class="per-page">
                            <div class="form-group">
                                <select class="form-select" [(ngModel)]="perPage" (ngModelChange)="onPerPageChanged()">
                                    <option [ngValue]="5"><span>5</span></option>
                                    <option [ngValue]="10"><span>10</span></option>
                                    <option [ngValue]="20"><span>20</span></option>
                                    <option [ngValue]="50"><span>50</span></option>
                                </select>
                            </div>
                            <span>Serviceverzoeken per pagina</span>
                        </div>
                        <div class="per-page">
                            <span>Totaal: {{filteredViewObjects.value.length}}</span>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>