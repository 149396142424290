import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {
  // Insterted by the ModalService
  entryIdentifier: string = ''
  entryType: string = ''
  entry: any
  callback: CallableFunction = () => {}

  constructor(private modalService: ModalService) {}

  ngAfterViewInit() {
  }

  closeModal() {
    this.modalService.close()
  }
}
