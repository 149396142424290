export enum Module {
    mech_plan = 'Monteursplanning',
    ser_contr = 'Servicecontracten',
    wvb = 'Werkvoorbereiding',
    mech_app = 'Monteurs App',
    non_conform = 'Non Conformiteiten',
    iss_track = 'Issue Tracker',
    contr_list = 'Controle-Lijsten',
    ass_manage = 'Assets Beheer',
    settings = 'Instellingen',
    mat_req = 'Materiaalbehoefte',
    spa_parts = 'Spare-Parts',
    time_reg = 'Urenregistratie',
    proj_tasks = 'Machine-Project-Taken',
    machConst_log = 'Machinebouw-Logboek',
    prjct_mng = 'Projecten'
}

export enum ModuleIcon {
    mech_plan = 'bi-calendar-week',
    wvb = 'bi-list-check',
    mech_app = 'bi-person-lines-fill',
    non_conform = 'bi-exclamation-circle',
    iss_track = 'bi-bug-fill',
    contr_list = 'bi-ui-checks',
    settings = 'bi-gear',
    ass_manage = 'bi-boxes',
    ser_contr = 'bi-file-earmark-text',
    mat_req = 'bi-stack',
    spa_parts = 'bi-nut',
    time_reg = 'bi-clock',
    proj_tasks = 'bi-calendar2-range',
    machConst_log = 'bi-cone-striped',
    prjct_mng = 'bi bi-kanban'
}

export const getModuleIcon = (mod: string) => {
    return ModuleIcon[mod as keyof typeof ModuleIcon]
}