@if(!machine?.company && !serviceRequest?.machineId){
    <div class="row mt-2">
        <div class="col">
            <div class="alert alert-warning required-input" role="alert">
                <span><i class="bi bi-info-circle"></i> Geef eerst het <strong>machinenummer</strong> op</span>
              </div>
        </div>
    </div>
}
@if(!machine && serviceRequest?.machineId && !newMachine){
    <div class="row mt-2">
        <div class="col">
            <div class="alert alert-info required-input" role="alert">
                <span><i class="bi bi-info-circle"></i> Geen machine gevonden. Nieuwe machine aanmaken?</span>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-success" (click)="createMachine()"><i class="bi bi-plus-square"></i> Machine aanmaken</button>
            </div>
        </div>
    </div>
}
@if(newMachine && !newCompany){
    <app-asset-management-machine-edit [serviceRequest]="serviceRequest" (createCompany)="createCompany()"></app-asset-management-machine-edit>
}
@if(newCompany){
    <app-asset-management-company-edit [serviceRequest]="serviceRequest" [company]="machine?.company" (canceledCreation)="canceldCompanyCreation()" (companyCreated)="createdCompany($event)"></app-asset-management-company-edit>
}
@if(machine && !newMachine && !newCompany){
    <div class="pl mt-2">
        @if(machine.company){
            <div class="row col wrapper pt-2">
                <div class="form-group col-3">
                    <label for="inputCustomerId">Klantnummer:</label>
                    <input type="number" class="form-control-plaintext" readonly id="inputCustomerId" placeholder="Onbekend" [(ngModel)]="machine.company.customerRelationId">
                </div>
                <div class="form-group col">
                    <label for="inputCustomerName">Klantnaam:</label>
                    <input type="text" class="form-control-plaintext" readonly id="inputCustomerName" placeholder="Onbekend" [(ngModel)]="machine.company.companyName">
                </div>
            </div>
        }
    </div>
    <div class="pl mt-2">
        <div class="row col wrapper">
            <h6><i class="bi bi-boxes"></i> Machinegegevens:</h6>
            <div class="form-group col-8">
                <label for="inputStreet">Straatnaam:</label>
                <input type="text" class="form-control-plaintext" readonly id="inputStreet" placeholder="Onbekend" [(ngModel)]="machine.streetName">
            </div>
            <div class="form-group col">
                <label for="inputNumber">Huisnummer:</label>
                <input type="text" class="form-control-plaintext" readonly id="inputNumber" placeholder="Onbekend" [(ngModel)]="machine.houseNumber">
            </div>
        </div>
    </div>
    <div class="pl">
        <div class="row col wrapper">
            <div class="form-group col-8">
                <label for="inputCity">Plaatsnaam:</label>
                <input type="text" class="form-control-plaintext" readonly id="inputCity" placeholder="Onbekend" [(ngModel)]="machine.city">
            </div>
            <div class="form-group col">
                <label for="inputZipcode">Postcode:</label>
                <input type="text" class="form-control-plaintext" readonly id="inputZipcode" placeholder="Onbekend" [(ngModel)]="machine.zipCode">
            </div>
            <div class="form-group">
                <label for="inputCountry">Land:</label>
                <input type="text" class="form-control-plaintext" readonly id="inputCountry" placeholder="Onbekend" [(ngModel)]="machine.country">
            </div>
        </div>
    </div>
}