import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { LogEntry } from 'src/app/models/logEntry';
import { LogBookLineComponent } from './log-book-line/log-book-line.component';
@Component({
  selector: 'app-log-book',
  standalone: true,
  imports: [CommonModule, LogBookLineComponent],
  templateUrl: './log-book.component.html',
  styleUrl: './log-book.component.scss'
})
export class LogBookComponent {
  // Insterted by the ModalService
  logEntries: Partial<LogEntry>[] = []

  constructor(private modalService: ModalService) {}

  ngAfterViewInit() {
    this.logEntries = this.logEntries.sort((a,b) => {return new Date(b.$createdAt || 0).getTime() - 
      new Date(a.$createdAt || 0).getTime()})
  }

  closeModal() {
    this.modalService.close()
  }
}