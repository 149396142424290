import { Component, ElementRef, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DBT, Relationship } from 'src/app/models/db/models';
import { Role } from 'src/app/models/roleEnum';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { RepositoryService } from 'src/app/services/repository/repository.service';
import { Models } from 'appwrite';
import { Base } from 'src/app/components/base/base.component';

@Component({
  selector: 'app-work-preparation-task-create',
  templateUrl: './work-preparation-task-create.component.html',
  styleUrl: './work-preparation-task-create.component.scss'
})
export class WorkPreparationTaskCreateComponent extends Base {

  @Input() wvbTask?: Partial<DBT['default']['WVBTask']&Models.Document>
  @Input() serviceRequest?: ServiceRequest
  newLogEntry: Partial<Relationship<'default','logEntry'>>

  constructor(private communicationService: CommunicationService,
    private permissionService: PermissionService, private repositoryService: RepositoryService,
    element: ElementRef
  ) { 
    super(element)
    
    // LogEntry Related
    let uName = permissionService.currentUser?.name
    let uRole = Role[permissionService.currentUserRole as keyof typeof Role]
    this.newLogEntry = {by: `${uName} (${uRole})`, message: ''} as Relationship<'default', 'logEntry'>
  }

  ngOnInit(): void {
    // Prevent Add/Edit from staying open on wrong SRQ
    this.addSub(this.communicationService.currentServiceRequest, () => {
      if (this.serviceRequest?.$id != this.communicationService.currentServiceRequest.value?.$id) {
        this.close()
      }
    })
  }

  /**
   * Trigger close operation 
   */
  close(){
    this.wvbTask = undefined;
    this.communicationService.currentWVBTask.next(undefined);
  }

   /**
   * This method will create a new WVBTask
   * @returns 
   */
   async createWVBTask(){
    if(!this.wvbTask){
      return;
    }

    if(this.newLogEntry.message && this.newLogEntry.message?.length > 0) {
      // @ts-ignore TODO fix typing
      this.wvbTask.logEntries?.push(this.newLogEntry)
    }

    if(this.wvbTask.$id != "") {
      // Edit Current
      this.repositoryService.update('WVBTask', this.wvbTask).then(async result => {
        if(!result || !this.wvbTask){
          return;
        }

        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het aanpassen van WVB Taak ${this.wvbTask?.name} is gelukt!`);
      
        this.wvbTask = undefined;
        this.communicationService.currentWVBTask.next(undefined);
        
        return Promise.resolve();
      });

    } else {
      // Create New
      this.repositoryService.create('WVBTask', this.wvbTask).then(async result => {
        if(!result || !this.wvbTask){
          return;
        }

        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het toevoegen van WVB Taak ${this.wvbTask?.name} is gelukt!`);

        this.wvbTask = undefined;
        this.communicationService.currentWVBTask.next(undefined);
        
        return Promise.resolve();
      });
    }
  }

  /**
   * Callback event for changing the Deadline
   * @param event 
   */
  onDeadlineValuesChanged(event: any){
    if(this.wvbTask){
      this.wvbTask.deadline = moment(event).toDate().toISOString();
    }
  }
}
