import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Company } from 'src/app/models/company';
import { Machine } from 'src/app/models/machine';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { AssetManagementContactPersonEditComponent } from "../asset-management-contact-person-edit/asset-management-contact-person-edit.component";
import { ServiceRequest } from 'src/app/models/service-request';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-asset-management-company-edit',
  standalone: true,
  imports: [FormsModule, CommonModule, AssetManagementContactPersonEditComponent],
  templateUrl: './asset-management-company-edit.component.html',
  styleUrl: './asset-management-company-edit.component.scss'
})
export class AssetManagementCompanyEditComponent {

  @Input() serviceRequest?: ServiceRequest;
  @Output() companyCreated = new EventEmitter();
  @Output() canceledCreation = new EventEmitter();
  @Input()company?: Company;

  companies?: Company[];
  mergeWithCompany?: Company;

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(private communicationService: CommunicationService, private companyService: CompanyService, private machineService: MachineService){
    
  }
  
  ngOninit() {
    this.subs.push(
      this.communicationService.activeCompanies?.subscribe(companies => {
        this.companies = companies.filter(c => c != this.company);
      })
    )
  }
  
  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  close(){
     // If the ServiceRequest is present then fire the canceled event
     if(this.serviceRequest){
      this.canceledCreation?.emit();
    }

    this.communicationService.activeCompany.next(undefined);
  }

  /**
   * This method will return the amount of machines
   * @param company 
   * @returns 
   */
  getAmountOfMachines(company: Company){
    return this.communicationService.activeMachines?.getValue()?.filter(m => m.relatedCompanyId == company.$id)?.length ?? 0;
  }

  /**
   * Thus method will Create or Update an (existing) company
   */
  addOrUpdateCompany(){
    if(this.company?.$id){
      this.companyService.updateCompany(this.company).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het updaten van klant ${this.company?.companyName} is gelukt!`);
  
        // Close window
        this.communicationService.activeCompany.next(undefined);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het updaten van klant ${this.company?.companyName} is mislukt!`);
      });
    } else if(this.company) {
      this.companyService.addCompany(this.company).then((createCompany) => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het aanmaken van klant ${this.company?.companyName} is gelukt!`);
  
        // If the ServiceRequest is present then fire the created event
        if(this.serviceRequest){
          this.companyCreated?.emit(createCompany as Company);
        }
     
        // Update active set of companies
        var activeCompanies = this.communicationService.activeCompanies.getValue();
        activeCompanies.push(createCompany as Company);
        this.communicationService.activeCompanies?.next(activeCompanies);

        // Close window
        this.communicationService.activeCompany.next(undefined);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het aanmaken van klant ${this.company?.companyName} is mislukt!`);
      });
    }
  }

  /**
   * This function will merge two companies into one company
   */
  async mergeCompany(){
    if(!this.mergeWithCompany?.$id || !this.company){
      return;
    }

    // Find related Machines
    let relatedMachines = this.communicationService.activeMachines?.getValue()?.filter(m => m.relatedCompanyId == this.company!.$id);
    let updatedMachines: Machine[] = [];

    // Adjust the machines to attach to the Company to merge with
    await Promise.all(relatedMachines?.map(async m => {
      // Check if an update is needed
      if(m.relatedCompanyId != this.mergeWithCompany?.$id){
        m.relatedCompanyId = this.mergeWithCompany?.$id;
        updatedMachines.push(m);
        return this.machineService.updateMachine(m);
      }

      return Promise.resolve();
    }));

    this.companyService.deleteCompany(this.company).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Machines (${updatedMachines?.length ?? 0}): ${updatedMachines?.map(m => m.machineId).join(', ')} zijn aangepast`)

      // Update active set of companies
      var activeCompanies = this.communicationService.activeCompanies.getValue();
      activeCompanies = activeCompanies.filter(c => c != this.company);
      this.communicationService.activeCompanies?.next(activeCompanies);

      // Close window
      this.communicationService.activeCompany.next(undefined);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van bedrijf ${this.company?.companyName} is mislukt!`);
    });
  }
}
