import { Injectable } from '@angular/core';
import { ID, Models, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { DBT } from 'src/app/models/db/models';
import { WVBTaskPreset } from 'src/app/models/wvb-task-preset';
import { environment } from 'src/environments/environment';

type WVBTaskPresetDB = Models.Document & {
  name: string,
  wVBTaskList: string[]
}

@Injectable({
  providedIn: 'root'
})
export class WvbTaskPresetService {

  wvbTaskPresetCollection = "WVBTaskPresets"

  constructor() { }

   /**
   * This method will retrieve all WVB Tasks of the current team
   */
   async getWVBTaskPresets() {
    var resultCount = 50;
    var resultList: WVBTaskPresetDB[] = [];
    var lastId: string | undefined = undefined;
    var results: Models.DocumentList<WVBTaskPresetDB>;
    let wvbTaskPresetList: WVBTaskPreset[] = []

    while(resultCount == 50){
      if(lastId){
        results = await Api.database.listDocuments<WVBTaskPresetDB>(
          environment.database,
          this.wvbTaskPresetCollection,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        results = await Api.database.listDocuments<WVBTaskPresetDB>(
          environment.database,
          this.wvbTaskPresetCollection,
          [Query.limit(50)]
        );
      }

      lastId = results?.documents[results.documents.length - 1]?.$id;
      resultList.push(...results.documents);
      resultCount = results.documents.length;
    }

    // Parse String
    resultList.forEach(preset => {
      let tasks: Partial<DBT['default']['WVBTask']>[] = []
      preset['wVBTasks'].forEach((pTask: string) => {
        // Is string in DB so must parse
        tasks.push(<Partial<DBT['default']['WVBTask']>>JSON.parse(pTask))
      })

      wvbTaskPresetList.push({...preset, wVBTasks: tasks})
    })

    return wvbTaskPresetList;
  }

  /**
    * This method will create a new ServiceRequest in the database
  */
  addWVBTaskPreset(wvbTask: WVBTaskPreset) {
    return Api.database.createDocument(environment.database, this.wvbTaskPresetCollection, ID.unique(), this.createPartialWVBTaskPreset(wvbTask))
  }

  /**
   * This method will update an existing ServiceRequest in the Database
  */
  updateWVBTaskPreset(wvbTask: WVBTaskPreset) {
    return Api.database.updateDocument(environment.database, this.wvbTaskPresetCollection, wvbTask.$id, this.createPartialWVBTaskPreset(wvbTask));
  }

  /**
   * This method will delete an existing ServiceRequest from the database
  */
  deleteWVBTaskPreset(wvbTask: WVBTaskPreset) {
    return Api.database.deleteDocument(environment.database, this.wvbTaskPresetCollection, wvbTask.$id);
  }

  /**
   * Creates a partial instance of the ServiceRequest without AppWrite properties
   * @param vehicle 
   * @returns 
  */
  private createPartialWVBTaskPreset(wvbTask: WVBTaskPreset): Partial<WVBTaskPresetDB> {
    if (!wvbTask) {
      return {};
    }
    let stringifiedList: string[] = []
    wvbTask.wVBTasks.forEach(tsk => {
      stringifiedList.push(JSON.stringify(tsk))
    })

    return {
      name: wvbTask.name,
      wVBTasks: stringifiedList
    };
  }
}
