import { Injectable } from '@angular/core';
import { ID, Models, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { EstimateLine } from 'src/app/models/estimate-line';
import { Machine } from 'src/app/models/machine';
import { MaintenanceMoment } from 'src/app/models/maintenance-moment';
import { ServiceContract } from 'src/app/models/serviceContract';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceContractService {

  serviceContractStatus: any[];
  serviceContractTable: string = "ServiceContracts"

  constructor() {
    this.serviceContractStatus = [
      {
        value: "Actueel",
        description: "Actueel",
        iconSource: "bi bi-check-circle",
        color: "#198754",
        id: 1
      },
      {
        value: "Beëindigd",
        description: "Beëindigd",
        iconSource: "bi bi-exclamation-circle",
        color: "#ff3333",
        id: 2
      },
      {
        value: "Nieuw",
        description: "Nieuw",
        iconSource: "bi bi-plus-circle",
        color: "#ff6600",
        id: 3
      },
    ];
  }

  /**
   * This method will retrieve all present ServiceContracts
   * @returns 
   */
  async getServiceContracts(){
    var resultCount = 50;
    var issuesList: ServiceContract[] = [];
    var lastId: string | undefined = undefined;
    var issues: Models.DocumentList<ServiceContract>;

    while (resultCount == 50) {
      if(lastId){
        issues = await Api.database.listDocuments<ServiceContract>(
          environment.database,
          this.serviceContractTable,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        issues = await Api.database.listDocuments<ServiceContract>(
          environment.database,
          this.serviceContractTable,
          [Query.limit(50)]
        );
      }

      lastId = issues?.documents[issues.documents.length - 1]?.$id;
      issuesList.push(...issues.documents);
      resultCount = issues.documents.length;
    }

    return issuesList;
  }

  /**
    * This method will create a new ServiceContract in the database
    */
  addServiceContract(serviceContract: ServiceContract) {
    return Api.database.createDocument(environment.database, this.serviceContractTable, ID.unique(), this.createPartialServiceContract(serviceContract, true))
  }

  /**
   * This method will update an existing ServiceContract in the Database
   */
  updateServiceContract(serviceContract: ServiceContract) {
    return Api.database.updateDocument(environment.database, this.serviceContractTable, serviceContract.$id, this.createPartialServiceContract(serviceContract));
  }

  /**
   * This method will delete an existing ServiceContract from the database
   */
  async deleteServiceContract(serviceContract: ServiceContract) {

    // Remove related documents
    if(serviceContract.referencedDocuments){
      await Promise.all( serviceContract.referencedDocuments.split(' ').map(rd => {
        return this.removeContractDocument(rd);
      }));
    }

    return Api.database.deleteDocument(environment.database, this.serviceContractTable, serviceContract.$id);
  }

  /**
   * This function will try to retrieve file meta data by the provided id
   * @param file 
   * @returns 
   */
  getContractDocuments(fileId: string) {
    return Api.storage.getFile(
      'ServiceContractResources',
      fileId
    );
  }

  /**
   * This function will upload a file to the bucket
   * @param file 
   * @returns 
   */
  uploadContractDocument(file: File) {
    return Api.storage.createFile(
      'ServiceContractResources',
      ID.unique(),
      file
    );
  }

  /**
   * This function will remove a file from the bucket
   * @param file 
   * @returns 
   */
  removeContractDocument(fileId: string) {
    return Api.storage.deleteFile(
      'ServiceContractResources',
      fileId
    );
  }

  /**
   * Creates a partial instance of the ServiceContract without AppWrite properties
   * @param serviceContract 
   * @returns 
   */
  private createPartialServiceContract(serviceContract: ServiceContract, fromCreate: boolean = false): Partial<ServiceContract> {
    if (!serviceContract) {
      return {};
    }

    if(fromCreate){
      return {
        contractNumber: serviceContract.contractNumber,
        startDate: serviceContract.startDate,
        endDate: serviceContract.endDate,
        status: serviceContract.status,
        additionalMessage: serviceContract.additionalMessage,
        maintenanceMoments: (serviceContract.maintenanceMoments ?? []).map(m => this.createPartialMaintenanceMoment(m)),
        referencedDocuments: serviceContract.referencedDocuments
      };
    }

    return {
      contractNumber: serviceContract.contractNumber,
      startDate: serviceContract.startDate,
      endDate: serviceContract.endDate,
      status: serviceContract.status,
      additionalMessage: serviceContract.additionalMessage,
      referencedMachine: this.createPartialReferencedMachine(serviceContract.referencedMachine),
      maintenanceMoments: (serviceContract.maintenanceMoments ?? []).map(m => this.createPartialMaintenanceMoment(m)),
      referencedDocuments: serviceContract.referencedDocuments
    };
  }

  /**
   * Creates a partial instance of the MaintenanceMoment
   * @param serviceContract 
   * @returns
   */
    private createPartialMaintenanceMoment(maintenanceMoment: Partial<MaintenanceMoment>): Partial<MaintenanceMoment> {
      
      if(maintenanceMoment.$id){
        return {
          $id: maintenanceMoment.$id,
          frequency: maintenanceMoment.frequency,
          description: maintenanceMoment.description,
          estimateLines: (maintenanceMoment.estimateLines ?? []).map(e => this.createPartialEstimateLine(e))   
        };
      }

      return {
        frequency: maintenanceMoment.frequency,
        description: maintenanceMoment.description,
        estimateLines: (maintenanceMoment.estimateLines ?? []).map(e => this.createPartialEstimateLine(e))
      };
    }

  /**
   * Creates a partial instance of the MaintenanceMoment
   * @param serviceContract 
   * @returns
   */
    private createPartialEstimateLine(estimateLine: Partial<EstimateLine>): Partial<EstimateLine> {
      if(estimateLine.$id){
        return {
          $id: estimateLine.$id,
          costType: estimateLine.costType,
          description: estimateLine.description,
          distanceTo: estimateLine.distanceTo,
          hours: estimateLine.hours,
          rate: estimateLine.rate
        };
      }

      return {
        costType: estimateLine.costType,
        description: estimateLine.description,
        distanceTo: estimateLine.distanceTo,
        hours: estimateLine.hours,
        rate: estimateLine.rate
      };
    }


    /**
   * Creates a partial instance of the Machine
   * @param serviceContract 
   * @returns 
   */
    private createPartialReferencedMachine(machine: Partial<Machine> | undefined): Partial<Machine | undefined> {
      if (!machine) {
        return undefined;
      }

      if(machine.$id){
        return {
          $id: machine.$id,
        };
      }

      return undefined;
    }

  /**
   * This method will return the icon resource based on the status
  */
  getStatusIcon(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.serviceContractStatus.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This method will return the color resource based on the status
  */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.serviceContractStatus.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }
}
