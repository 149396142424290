import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { 
    console.log("Logging service set up in mode: " + "DEBUG")
  }

  debug(message: string, loc?: LogLocation) {
    if(environment.logging == "production") {return}
    loc? console.log(`%c [${loc}]: ${message}`, 'color:yellow;') :
      console.log(`%c ${message}`, 'color:yellow;')
  }

  debugObj(message: string, obj?: any, loc?: LogLocation) {
    if(environment.logging == "production") {return}

    loc? console.log(`%c [${loc}]: ${message}`, 'color:yellow;') :
      console.log(`%c ${message}`, 'color:yellow;')
    if(obj) {
      console.log(obj)
    }
  }

  error(message: string, loc?: LogLocation) {
    loc? console.log(`%c [${loc}]: ${message}`, "color:red;") :
      console.log(`%c ${message}`, "color:red;")
  }
}

type LogLocation = "Repository" | "Permission" | "Auth" | "BaseTable" | "View"
