import { Client, Account, Databases, Teams, Storage, Functions } from 'appwrite';
import { environment } from 'src/environments/environment';

export class Api {
    public static database: Databases;
    public static account: Account;
    public static teams: Teams;
    public static storage: Storage;
    public static functions: Functions;
    public static client: Client; //Required for Realtime

    static provider() {
        const client = new Client()
            .setEndpoint(environment.backendUrl)
            .setProject('default');

        this.database = new Databases(client);    
        this.account = new Account(client);    
        this.teams = new Teams(client);  
        this.storage = new Storage(client);  
        this.functions = new Functions(client);
        this.client = client;  
    }
}