import { Component, Input, CUSTOM_ELEMENTS_SCHEMA, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import * as moment from 'moment';
import { ModalService } from 'ngx-modal-ease';
import { MultiSelectModule } from 'primeng/multiselect';
import { BehaviorSubject } from 'rxjs';
import { BaseTable } from 'src/app/components/base/base-table.component';
import { LogBookComponent } from 'src/app/components/protected/log-book/log-book.component';
import { DBT } from 'src/app/models/db/models';
import { WVBTaskPreset } from 'src/app/models/wvb-task-preset';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { RepositoryService } from 'src/app/services/repository/repository.service';
import { ServiceRequestService } from 'src/app/services/service-request/service-request.service';
import { WvbTaskPresetService } from 'src/app/services/wvb-task-preset/wvb-task-preset.service';
import { Models } from 'appwrite';
import { ConfirmationComponent } from 'src/app/components/base/confirmation/confirmation.component';
import { Department } from 'src/app/models/departmentEnum';

// We define the internal type that will be used as our view object
type ViewObject = {
  wvbTask: (DBT['default']['WVBTask']& Models.Document)
} 

@Component({
  standalone: true,
  imports: [
    FormsModule,
    BrowserModule,
    MultiSelectModule
  ],
  // Fix p-Multiselect web component error for standalone module
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  selector: 'app-work-preparation-tasks-table',
  templateUrl: './work-preparation-tasks-table.component.html',
  styleUrl: './work-preparation-tasks-table.component.scss'
})

export class WorkPreparationTasksTableComponent extends BaseTable<ViewObject> {

  allWVBTasks: BehaviorSubject<(DBT['default']['WVBTask']&Models.Document)[]> = new BehaviorSubject([] as (DBT['default']['WVBTask']&Models.Document)[]);
  //For filtering
  activeServiceRequest: BehaviorSubject<DBT['default']['serviceRequest']&Models.Document | undefined> = new BehaviorSubject<DBT['default']['serviceRequest']&Models.Document | undefined>(undefined);
  activeWVBTask: BehaviorSubject<DBT['default']['WVBTask']&Models.Document | undefined> = new BehaviorSubject<DBT['default']['WVBTask']&Models.Document | undefined>(undefined);
  activeCompany: BehaviorSubject<DBT['default']['company']&Models.Document | undefined> = new BehaviorSubject<DBT['default']['company']&Models.Document | undefined>(undefined);
  
  selectedPreset: WVBTaskPreset | undefined
  allPresets: WVBTaskPreset[] = []

  showClosedTasks?: boolean;

  // Show Description of Tasks in this array
  showDescriptionTaskIds: string[] = []

  // View Only (Mainly used for Service-Request-Edit)
  @Input() viewOnly = false;

  // Show All Mode (Used at top of work-prep-dashboard)
  @Input() showAllMode = false;

  wvbTaskStatus = [
    {
      value: "To Do",
      description: "To Do",
      iconSource: "bi bi-card-checklist",
      color: "#de9d87",
      id: 1
    },
    {
      value: "In Uitvoering",
      description: "In Uitvoering",
      iconSource: "bi bi-card-checklist",
      color: "#87dede",
      id: 2
    },
    {
      value: "Uitgevoerd",
      description: "Uitgevoerd",
      iconSource: "bi bi-card-checklist",
      color: "#de87de",
      id: 3
    },
  ];

  allDepartments = [
    {'name': 'Engineering'},
    {'name': 'Elektro'},
    {'name': 'Inkoop'},
    {'name': 'Bedrijfsbureau'},
    {'name': 'Plaatwerkerij'},
    {'name': 'Draaien-en-frezen'},
    {'name': 'Machinebouw'},
    {'name': 'Kwaliteit'},
    {'name': 'Service'},
    {'name': 'Magazijn'},
    {'name': 'Finance'},
    {'name': 'Verkoop'},
    {'name': 'ICT'},
    {'name': 'Operations'},
    {'name': 'GeneralManagement'},
  ]
  selectedDepartments = [
    {'name': 'Engineering'},
    {'name': 'Elektro'},
    {'name': 'Inkoop'},
    {'name': 'Bedrijfsbureau'},
    {'name': 'Plaatwerkerij'},
    {'name': 'Draaien-en-frezen'},
    {'name': 'Machinebouw'},
    {'name': 'Kwaliteit'},
    {'name': 'Service'},
    {'name': 'Magazijn'},
    {'name': 'Finance'},
    {'name': 'Verkoop'},
    {'name': 'ICT'},
    {'name': 'Operations'},
    {'name': 'GeneralManagement'},
  ]

  isViewer = false
  @Input() newWVBTask?: DBT['default']['WVBTask']&Models.Document

  constructor(public communicationService: CommunicationService, public serviceRequestService: ServiceRequestService, 
    public wvbTaskPresetService: WvbTaskPresetService, private modalService: ModalService, private repositoryService: RepositoryService,
    private logger: LoggingService, element: ElementRef
  ) { 
    super(element, "WVBTASKTABLE", logger);

    // Filter Related
    this.selectedDepartments = this.allDepartments
    this.showClosedTasks = localStorage.getItem("WVBTAKSTABLE_SHOWCLOSED") == "true";

    this.wvbTaskPresetService.getWVBTaskPresets().then(presets => {
      this.allPresets = presets
      this.selectedPreset = presets[0]
    })
  }

  ngOnInit(): void {

    this.repositoryService.getSubscription("WVBTask").then(bs => {
      this.allWVBTasks = bs
      this.addSub(bs, this.createViewableObjects.bind(this))
    })

    // TODO: Does it make sense to use a local reference?
    this.activeWVBTask = this.communicationService.currentWVBTask
    
    // We do not want to filter SRQ in showallmode
    if(!this.showAllMode) {
      this.activeServiceRequest = this.communicationService.currentServiceRequest
      this.activeCompany = this.communicationService.currentCompany
      this.addSub(this.communicationService.currentServiceRequest, this.createViewableObjects.bind(this))
    }
   
  }

  override createViewableObjects(): void {
    if(this.allWVBTasks.value.length < 1) {
      this.logger.debug("Required Repos not yet fetched")
      return
    }

    let viewObjects: ViewObject[] = []
    for (let i = 0; i < this.allWVBTasks.value.length; i++) {
      let wvbt = this.allWVBTasks.value[i];
      let vo: ViewObject = {wvbTask: wvbt}
      viewObjects.push(vo)
    }

    super.createViewableObjects(viewObjects)
  }

  /**
   * Private function to call, for view: ViewSort
   */
  override filterSortAndPaginate(column: string, as: 'number' | 'string' | 'Date'): void {
    // Filter is Component-dependent
    // 1: Filter

    //Filter selected ServiceRequest iff not undefined
    let filtered = this.allViewObjects.value
    if(this.activeServiceRequest.value) {
      let aSRQ = this.activeServiceRequest.value
      filtered = this.allViewObjects.value.filter(t => t.wvbTask.serviceRequestId == aSRQ.$id)
    }
    
    // Filter Department
    let selectedDepartmentArr = this.selectedDepartments.map(s => s.name);
    filtered = filtered.filter(t => selectedDepartmentArr.indexOf(t.wvbTask.department) != -1)

    // Filter for Archived
    if(!this.showClosedTasks){
      filtered = filtered.filter(t => !t.wvbTask.archived)
    }

    this.filteredViewObjects.next(filtered)
    
    // Call Super for Sorting and Paginating
    super.filterSortAndPaginate(column, as)

  }
 


  /**
   * This method remove the Service Request filter and repaginate all present WVBTasks
   * @returns 
   */
  removeServiceRequestFilter() {
    // Legacy
    this.communicationService.activeServiceRequest.next(undefined)
    // New
    this.communicationService.currentServiceRequest.next(undefined)
    this.communicationService.currentCompany.next(undefined)
    this.communicationService.currentMachine.next(undefined)
    this.communicationService.currentWindows.next(undefined)
    this.viewFilter()
  }

  toggleShowPerformedTasks(){
    localStorage.setItem("WVBTAKSTABLE_SHOWCLOSED", `${this.showClosedTasks}`);
    this.viewFilter()
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
    onClickLostFocus() {
      if (this.communicationService.openStatusWVBTaskIdChanged) {
        this.communicationService.openStatusWVBTaskIdChanged = false;
        return;
      }
  
      this.communicationService.openStatusWVBTaskIdChanged = false;
      this.communicationService.openStatusWVBTaskId = undefined;
    }

  /**
   * This method will set the ServiceRequest id to the given id so that the other status line could be accessed
   * @param wvbTaskId 
   */
  onStatusExpanderClick(wvbTaskId: string | undefined) {
    this.communicationService.openStatusWVBTaskIdChanged = true;
    if (this.communicationService.openStatusWVBTaskId == wvbTaskId) {
      this.communicationService.openStatusWVBTaskId = undefined;
    }
    this.communicationService.openStatusWVBTaskId = wvbTaskId;
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    let statusResource = this.wvbTaskStatus.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will return the icon resource based on the status
  */
  getStatusIcon(type: string) {
    if (!type) {
      return;
    }

    let statusResource = this.wvbTaskStatus.filter(s => s.value == type);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This method will update the ServiceRequests status
   * @param ServiceRequests 
   */
  statusSelectionChanged(vo: ViewObject, status: string) {
    let wvbTask = vo.wvbTask
    if (!wvbTask || !status) {
      return;
    }
    
    // Change status
    var currentStatus = wvbTask.status + "";
    wvbTask.status = status;
    wvbTask.archived = status == "Uitgevoerd";

    this.repositoryService.update('WVBTask', wvbTask).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het updaten van de status van de taak ${wvbTask?.name} is gelukt!`);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het updaten van de status van de taak ${wvbTask?.name} is mislukt!`);
        wvbTask.status = currentStatus;
      });

  }

   
  /**
   * This method will create a new empty WVBTask
   */
  viewCreateWVBTask(){
    this.activeWVBTask.next(undefined);

    if (!this.activeServiceRequest?.value?.$id) {
      return
    }

    this.newWVBTask = {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      name: "",
      status: "To Do",
      description: "",
      serviceRequestId: this.activeServiceRequest?.value?.$id,
      department: Department.engineering,
      deadline: new Date(this.activeServiceRequest?.value?.startTime || new Date()).toISOString(),
      archived: false,
      logEntries: []
    }

    this.communicationService.currentWVBTask.next(this.newWVBTask)
   
    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('new-wvb-task')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will remove a ServiceRequest or show a cofirmation button
   * @param serviceRequest 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async viewRemoveWVBTask(vo: ViewObject, index: number, confirmationNeeded: boolean) {
    let wvbTask = vo.wvbTask
    if (confirmationNeeded) {
      this.modalService.open(ConfirmationComponent, {
        modal: {
          enter: 'enter-scale-down 0.1s ease-out',
        },
        overlay: {
          leave: 'fade-out 0.3s',
        },
        data: {
          entryIdentifier: wvbTask.name,
          entryType: 'WVB Taak',
          entry: vo,
          callback: this.viewRemoveWVBTask.bind(this)
        },
      })
      
      return;
    }

    // Remove ServiceRequest from the database
    this.repositoryService.delete('WVBTask', wvbTask).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van WVB Taak ${wvbTask?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van WVB Taak ${wvbTask?.name} is mislukt!`);
    });
  }


  /**
   * This method will set the active ServiceRequest which will be shown in the detail screen
   * @param serviceRequest 
   */
  viewEditWVBTask(vo: ViewObject){
    this.communicationService.currentWVBTask?.next(vo.wvbTask);

    this.newWVBTask = vo.wvbTask

    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('new-wvb-task')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will insert a list of WVT Presets
   */
  insertPreset(){
    if (!this.activeServiceRequest?.value?.$id) {
      return
    }
    let serviceRequest = this.activeServiceRequest

    this.selectedPreset?.wVBTasks.forEach(pTask => {
      if(!pTask.name || !pTask.description || !pTask.status || !pTask.department) {
        console.error("Missing pTask Info")
        return
      }
      this.repositoryService.create('WVBTask', {
        name: pTask.name,
        status: pTask.status,
        description: pTask.description,
        serviceRequestId: serviceRequest.value?.$id,
        department: pTask.department,
        deadline: moment(serviceRequest.value?.startTime).toDate(),
        archived: false
      }).then(res => {
        // Refresh list
        this.communicationService.currentWVBTask.next(undefined);
      })
    })

    this.communicationService.activeToastKind.next("Success");
    this.communicationService.activeToastMessage.next(`Het toevoegen van de Preset ${this.selectedPreset?.name} is gelukt!`);
    
  }

  toggleShowTaskDescription(vo: ViewObject) {
    let task = vo.wvbTask
    // Append Id. 
    // Look if ID in this array in view to determine whether or not 
    // To display Description.
    let idx = this.showDescriptionTaskIds.indexOf(task.$id)
    if (idx > -1) {
      this.showDescriptionTaskIds.splice(idx, 1)
    } else {
      this.showDescriptionTaskIds.push(task.$id)
    }
  }

  openLogBook(vo: ViewObject) {
    let wvbTask = vo.wvbTask
    this.modalService.open(LogBookComponent, {
      modal: {
        enter: 'enter-scale-down 0.1s ease-out',
      },
      overlay: {
        leave: 'fade-out 0.3s',
      },
      data: {
        logEntries: wvbTask.logEntries,
      },
    })
  }

}
