import { Component, OnInit } from '@angular/core';
import { Account, Models } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TeamService } from 'src/app/services/team/team.service';
import { TeamMember } from 'src/app/models/services/team-member';
import { UserPreferences } from 'src/app/models/services/user-preferences';
import { Role } from 'src/app/models/roleEnum';
import { Module, ModuleIcon } from 'src/app/models/moduleEnum';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { getDepartmentFromRole } from 'src/app/models/departmentEnum';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {
  
  teamMembers?: TeamMember[];
  newMember?: TeamMember;
  newMemberPass = "";
  successFeedback?: string;
  warningFeedback?: string;
  user?: Partial<Models.User<UserPreferences>>;
  activeTeamId?: string;
  
  roleStrings = Object.entries(Role);
  moduleStrings = Object.entries(Module);
  permissionMatrix: any;

  lastDepartment: any;//For Department printing

  selectedRole = "engineer"

  externalUsers?: TeamMember[] = [];


  constructor(private authService: AuthService, private teamService: TeamService,
    private communicationService: CommunicationService
  ) {
    Api.teams.getPrefs('66599e92000a2b5dfbf9').then(prefs => {
      if (prefs) {
        let pMatrix = prefs['permissions']
        this.permissionMatrix = JSON.parse(pMatrix)
      }
    })
   }

  ngOnInit(): void {
    Api.teams.list().then(teamsList => {
      var team = teamsList.teams.filter(t => t.name == "UNIKON");
      if (team?.length > 0) {
        this.activeTeamId = team[0].$id;

        var authUser = this.authService.user.getValue();
        if (authUser) {
          this.user = authUser;
          this.prepareTeamMembersList();
        }
      }
    });
  }

  /**
   * This method will prepare the list of memebers
   */
  private prepareTeamMembersList() {
    const excludeFromList = ["66e3f949221b1b456e3b", "66599e52aae933617b62"]
    // Martijn & Jip

    if (!this.activeTeamId) {
      return;
    }

    this.teamService.getTeamMembers(this.activeTeamId).then(teamMembers => {
      if (!teamMembers) {
        return;
      }

      this.teamMembers = [];
      teamMembers.memberships.forEach(teamMembersList => {
        if(excludeFromList.indexOf(teamMembersList.userId) == -1){ //If userId not in ExcludeFromList
          this.teamService.getMembership(this.activeTeamId!, teamMembersList.userId).then(membership => {
            this.teamMembers?.push({
              emailAdress: teamMembersList.userEmail,
              fullname: teamMembersList.userName,
              userId: teamMembersList.userId,
              membershipId: membership?.$id,
              roles: membership?.roles
            });
          })
        }
      });

      this.newMember = {
        emailAdress: "",
        fullname: "",
        userId: "un",
      }
    });
  }

  /**
   * This method will determine the role of a teammember
   * @param roles 
   * @returns 
   */
  private determineRoles(role: string){
    console.log(role)
    let roles = [role]
    if(roles.indexOf('general_manager') != -1 || roles.indexOf('operations_director') != -1) {
      roles.push('owner')
    } else if(roles.indexOf('zzp') != -1 || roles.indexOf('klant') != -1) {
      roles.push('viewer')
    } else {
      roles.push('employee')
    }
    // Add Appwrite Roles
    return roles
  }

  /**
   * This method will add a new user to the team
   */
  addUserToTeam() {
    if (!this.newMember || !this.activeTeamId) {
      // Give user feedback
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next( "Er is een onverwachte fout opgetreden. Probeer het later opnieuw.");
      return;
    }

    if (this.newMember.emailAdress.trim() == "") {
      // Give user feedback
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next("Vul eerst een e-mailadres in alvorens er een gebruiker kan worden toegevoegd.");
      return;
    }

    if (this.newMemberPass.trim() == "") {
      // Give user feedback
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next("Vul eerst een wachtwoord in alvorens er een gebruiker kan worden toegevoegd.");
      return;
    }

    let potentialUser = this.teamMembers?.filter(tm => tm.emailAdress === this.newMember?.emailAdress)
    if (potentialUser && potentialUser.length > 0) {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Deze gebruiker is al lid van het Team`);
      return
    }

    // Add user
    let payload = {
      'email': this.newMember.emailAdress,
      'teamId': this.activeTeamId,
      'roles': this.determineRoles(this.selectedRole || ""),
      'name': this.newMember.fullname,
      'password': this.newMemberPass
    }
    Api.functions.createExecution(
      "67405fa3002763078d10", //AddUserToTeam
      JSON.stringify(payload)
    ).then(resp => {
      if(resp.statusCode != 200){
        this.warningFeedback = "Interne fout bij Toevoegen"
        // Hide after 3 seconds
        setTimeout(() => { this.warningFeedback = undefined }, 3000);
      } else {
        try {
          const response = JSON.parse(resp.response)
          if(response['status'] != 200) {
            this.warningFeedback = response['message']
            setTimeout(() => { this.warningFeedback = undefined }, 3000);
          } else {
            this.successFeedback = "Gebruiker successvol toegevoegd"
            setTimeout(() => { this.successFeedback = undefined }, 3000);
            this.prepareTeamMembersList();
          }
          
        } catch (error) {
          this.warningFeedback = "Interne fout bij Toevoegen"
          setTimeout(() => { this.warningFeedback = undefined }, 3000);
        }
      }

    })

  }

  removeMemberFromTeam(teamMember: TeamMember) {
    if (!teamMember || !this.activeTeamId || !teamMember.membershipId) {
      // Give user feedback
      this.warningFeedback = "Er is een onverwachte fout opgetreden. Probeer het later opnieuw."
      // Hide after 3 seconds
      setTimeout(() => { this.warningFeedback = undefined }, 3000);
      return;
    }

    // Remove User from team
    this.teamService.deleteUserFromTeam(this.activeTeamId, teamMember.membershipId).then(() => {
      // Give user feedback
      this.successFeedback = "De gebruiker is succesvol verwijderd."
      // Hide after 3 seconds
      setTimeout(() => { this.successFeedback = undefined }, 3000);
      this.prepareTeamMembersList();
    }).catch(() => {
      // Give user feedback
      this.warningFeedback = "Gebruiker kon niet worden verwijderd."
      // Hide after 3 seconds
      setTimeout(() => { this.warningFeedback = undefined }, 3000);
    });
  }

  displayRole(roles: string[] | undefined){
    if(roles)
      return roles[0]
    return "null"
  }

  /**
   * This method will update the IsCompanyOwner Property
   * @param teamMember 
   * @returns 
   */
  updateCompanyRoles(teamMember: TeamMember, role: Event) {
    let newRole = <string><unknown>role;

    if (!this.activeTeamId || !teamMember.membershipId) {
      return;
    }

    if(this.user?.$id == teamMember.userId || !this.user || !teamMember.userId){
      return;
    }

    let roles: string[] = this.determineRoles(newRole);
    this.teamService.modifyMembershipRoles(this.activeTeamId, teamMember.membershipId, roles).then(membership => {
      if (membership) {
        // Give user feedback
        this.successFeedback = "De gebruiker is succesvol gewijzigd."
        // Hide after 3 seconds
        setTimeout(() => { this.successFeedback = undefined }, 3000);
      } else {
        this.warningFeedback = "Gebruiker kon niet worden gewijzigd."
        // Hide after 3 seconds
        setTimeout(() => { this.warningFeedback = undefined }, 3000);

        teamMember.roles = ["viewer"];
      }
    });
  }


  roleModChecked(role: string, mod: string) {
    if(this.permissionMatrix && this.permissionMatrix[role] != undefined) {
      return this.permissionMatrix[role].indexOf(mod) != -1
    }
    return false

  }

  async updateTeamPrefs(role: string, mod: string) {
    // Check if currently checked
    let curAllowed = false
    if(this.permissionMatrix && this.permissionMatrix[role] != undefined) {
      curAllowed = this.permissionMatrix[role].indexOf(mod) != -1
    }

    // Remove or add rights to module
    if(curAllowed){
      // Remove rights
      let newAllowed = this.permissionMatrix[role].filter((r: string) => r != mod)
      this.permissionMatrix[role] = newAllowed
    } else{
      // Add rights
      let newAllowed = this.permissionMatrix[role] || [] //Or array if role does not yet exist
      newAllowed.push(mod)
      this.permissionMatrix[role] = newAllowed
    }

    let stringMatrix = JSON.stringify(this.permissionMatrix)
    const result = await Api.teams.updatePrefs(
      '66599e92000a2b5dfbf9', // teamId
      {'permissions': stringMatrix} // prefs
    );
    if(!!result) {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het aanpassen is gelukt!`);
    }
  }

  // Get the icon to print
  getModuleIcon(mod: string) {
    return ModuleIcon[mod as keyof typeof ModuleIcon]
  }

  // Get the department name to print from the role
  getRoleDepartmentHeader(role: Role) {
    this.lastDepartment = getDepartmentFromRole(role)
    return this.lastDepartment
  }
  // To prevent printing department above every role
  canPrintRoleDepartmentHeader(role: Role) {
    let department = getDepartmentFromRole(role)
    if(this.lastDepartment == department)
      return false
    return true 
  }

  /**
   * This method will add a new user to the team
   */
  addExternalUser() {
    console.log("AddExternalUser to be implemented")
    return

  }

}
