import { Component, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Models } from 'appwrite';
import { BehaviorSubject } from 'rxjs';
import { BaseTable } from 'src/app/components/base/base-table.component';
import { DBT } from 'src/app/models/db/models';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { RepositoryService } from 'src/app/services/repository/repository.service';

// We define the internal type that will be used as our view object
type ViewObject = {
  serviceRequest: (DBT['default']['serviceRequest']&Models.Document)
  nrWVBTasks?: number
  referencedMachine?: (DBT['default']['machine']&Models.Document)
  company?: (DBT['default']['company']&Models.Document)
  windows?: (DBT['default']['window']&Models.Document)[]
}

@Component({
  selector: 'app-service-request-table',
  templateUrl: './service-request-table.component.html',
  styleUrls: ['./service-request-table.component.scss']
})

export class ServiceRequestTableComponent extends BaseTable<ViewObject> {
  @Input()
  // This function sets the selected statusses from outside: [selectedStatusCustom]='[{name:"Plannen"}]'
  set selectedStatusCustom(arr: { name: string; }[]) {
    this.selectedStatusses = arr;
  }

  @Input()
  set displayedColumns(cols: string[]) {
    // Check if we did not make programming mistake
    if(!cols.every(c => ['Name','Type','StartTime','Company','Status','WVBTasks','Actions'].indexOf(c) != -1)) {
      console.error("Passed on invalid col name!")
      console.log(cols)
    }
    
    this._displayedCols = cols
  }

  @Input()
  set delBtnsAvailable(avail: boolean) {
    this._delBtnsAvailable = avail
  }

  searchInput: string = "";

  allStatusses = [{name:"Plannen"},{name:"Gepland"},{name:"Voorbereid"},{name:"Uitgevoerd"},{name:"Afgehandeld"},{name:"Gefactureerd"}]
  selectedStatusses = [{name:"Plannen"},{name:"Gepland"},{name:"Voorbereid"},{name:"Uitgevoerd"}];
  
  isViewer?: boolean;

  // For Edit / Delete action, unavailable at WVB
  _delBtnsAvailable: boolean = true;
  _displayedCols: string[] = [];
  router: Router

  // Local References of the behavior Subjects
  allServiceRequests: BehaviorSubject<(DBT['default']['serviceRequest']&Models.Document)[]> = new BehaviorSubject([] as (DBT['default']['serviceRequest']&Models.Document)[]);
  allWVBTasks: BehaviorSubject<(DBT['default']['WVBTask']&Models.Document)[]> = new BehaviorSubject([] as (DBT['default']['WVBTask']&Models.Document)[]);
  allMachines: BehaviorSubject<(DBT['default']['machine']&Models.Document)[]> = new BehaviorSubject([] as (DBT['default']['machine']&Models.Document)[]);
  allCompanies: BehaviorSubject<(DBT['default']['company']&Models.Document)[]> = new BehaviorSubject([] as (DBT['default']['company']&Models.Document)[]);
  allWindows: BehaviorSubject<(DBT['default']['window']&Models.Document)[]> = new BehaviorSubject([] as (DBT['default']['window']&Models.Document)[]);

  serviceRequestStatus = [
    {
      value: "Plannen",
      description: "Plannen",
      iconSource: "bi bi-card-checklist",
      color: "#de9d87",
      id: 1
    },
    {
      value: "Gepland",
      description: "Gepland",
      iconSource: "bi bi-card-checklist",
      color: "#87dede",
      id: 2
    },
    {
      value: "Voorbereid",
      description: "Voorbereid",
      iconSource: "bi bi-card-checklist",
      color: "#c8de87",
      id: 3
    },
    {
      value: "Uitgevoerd",
      description: "Uitgevoerd",
      iconSource: "bi bi-card-checklist",
      color: "#de87de",
      id: 4
    },
    {
      value: "Afgehandeld",
      description: "Afgehandeld",
      iconSource: "bi bi-card-checklist",
      color: "#87de9d",
      id: 5
    }
  ];

  serviceRequestTypes = [
    {
      value: "Periodiek onderhoud",
      description: "Periodiek onderhoud",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 1
    },
    {
      value: "Onderhoud",
      description: "Onderhoud",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 2
    },
    {
      value: "Reparatie",
      description: "Reparatie",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 3
    },
    {
      value: "Vervangen onderdelen",
      description: "Vervangen onderdelen",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 4
    },
    {
      value: "Oplevering",
      description: "Oplevering",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 5
    },
    {
      value: "Reiniging",
      description: "Reiniging",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 6
    },
    {
      value: "Plaatsen",
      description: "Plaatsen",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 7
    },
    {
      value: "Servicecontract",
      description: "Servicecontract",
      iconSource: "bi bi-file-earmark-text",
      color: "#ff99ff",
      id: 8
    },
    {
      value: "Overig",
      description: "Overig",
      iconSource: "bi bi-wrench-adjustable-circle",
      color: "#ff99ff",
      id: 9
    }
  ];

  constructor(public communicationService: CommunicationService,
    router: Router, private repositoryService: RepositoryService,
    private logger: LoggingService,
    element: ElementRef
  ) { 
    super(element, "SERVICEREQUEST", logger);

    this.router = router
  }

  ngOnInit(): void {

    this.repositoryService.getSubscription("serviceRequest").then(bs => {
      this.allServiceRequests = bs
      this.addSub(bs, this.createViewableObjects.bind(this))
    })

    // Retrieve WVBTasks for # tasks only if we want to display them
    if (this._displayedCols.indexOf('WVBTasks') != -1) {
      this.repositoryService.getSubscription('WVBTask').then(bs => {
      this.allWVBTasks = bs
      this.addSub(bs, this.createViewableObjects.bind(this))
       })
    }

    this.repositoryService.getSubscription("company").then(bs => {
      this.allCompanies = bs
      this.addSub(bs, this.createViewableObjects.bind(this))
    })


    this.repositoryService.getSubscription("machine").then(bs => {
      this.allMachines = bs
      this.addSub(bs, this.createViewableObjects.bind(this))
    })
 

    this.repositoryService.getSubscription("window").then(bs => {
      this.allWindows = bs
      this.addSub(bs, this.createViewableObjects.bind(this))
    })

  }

  /**
   * This method is called if one of the models changes.
   * Recreates the viewObjects
   */
  override createViewableObjects() {
    if(this.allCompanies.value.length < 1 || this.allMachines.value.length < 1 
      || this.allWindows.value.length < 1 ) {
      this.logger.debug("Required Repos not yet fetched")
      return
    }
    let viewObjects: ViewObject[] = []
    // Attach Machines, Companies, WVB Task # amount and Windows in a refreshable way
    for (let i = 0; i < this.allServiceRequests.value.length; i++) {
      let srq = this.allServiceRequests.value[i];
      let vo: ViewObject = {serviceRequest: srq}

      if(this.allWVBTasks.value.length > 0){
        vo.nrWVBTasks = this.allWVBTasks.value.filter(w => w.serviceRequestId == srq.$id).length || 0
      }
      if(srq.referencedMachineId){
        let machine = this.allMachines.value.find(m => m.$id == srq.referencedMachineId)
        if(!machine) {continue}
        vo.referencedMachine = machine

        let referencedCompany = this.allCompanies.value.find(m => m.$id == machine.relatedCompanyId);
        vo.company = referencedCompany
      }
      vo.windows = this.allWindows.value.filter(w => w.serviceRequestId == vo.serviceRequest.$id)
      
      viewObjects.push(vo)
    }

    if(viewObjects.length < 1) {return}

    super.createViewableObjects(viewObjects)
  }

  

  /**
   * This method will set the active ServiceRequest which will be shown in the detail screen
   * @param serviceRequest 
   */
  editServiceRequest(vo: ViewObject){
    //TODO: This is Legacy
    this.communicationService.activeServiceRequest?.next(<ServiceRequest><unknown>{
      ...vo.serviceRequest,
      windows: vo.windows,
      referencedMachine: {...vo.referencedMachine,
        company: vo.company
      }
    });
    this.communicationService.currentServiceRequest.next(vo.serviceRequest)
    this.communicationService.currentCompany.next(vo.company)
    this.communicationService.currentWindows.next(vo.windows)
    this.communicationService.currentMachine.next(vo.referencedMachine)
     
    // Scroll new-section into view
     setTimeout(() => {
      document.getElementById('edit-service-request')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will set the active ServiceRequest and jump to WVB
   * @param serviceRequest 
   */
  jumpToWVB(vo: ViewObject){
    //TODO: This is Legacy
    this.communicationService.activeServiceRequest?.next(<ServiceRequest><unknown>{
      ...vo.serviceRequest,
      windows: vo.windows,
      referencedMachine: {...vo.referencedMachine,
        company: vo.company
      }
    });
    this.communicationService.currentServiceRequest.next(vo.serviceRequest)
    this.communicationService.currentCompany.next(vo.company)
    this.communicationService.currentWindows.next(vo.windows)
    this.communicationService.currentMachine.next(vo.referencedMachine)

    this.router.navigate(['/work-preparation-dashboard'])
    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('wvbTableModule')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will set the active ServiceRequest and jump to Planning
   * @param serviceRequest 
   */
  jumpToPlanning(vo: ViewObject){
    //TODO: This is Legacy
    this.communicationService.activeServiceRequest?.next(<ServiceRequest><unknown>{
      ...vo.serviceRequest,
      windows: vo.windows,
      referencedMachine: {...vo.referencedMachine,
        company: vo.company
      }
    });
    // New
    this.communicationService.currentServiceRequest.next(vo.serviceRequest)
    this.communicationService.currentCompany.next(vo.company)
    this.communicationService.currentWindows.next(vo.windows)
    this.communicationService.currentMachine.next(vo.referencedMachine)

    this.router.navigate(['/service-requests-dashboard'])
    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('edit-service-request')?.scrollIntoView();
    }, 200);
  }

  /**
   * Private function to call, for view: ViewSort
   */
  override filterSortAndPaginate(column: string, as: 'number' | 'string' | 'Date'): void {
    // Filter is Component-dependent
    // 1: Filter
    // Filter Statusses
    let selectedStatussesArr = this.selectedStatusses.map(s => s.name);
    let filtered = this.allViewObjects.value.filter(s => selectedStatussesArr.indexOf(s.serviceRequest.status) != -1);

    // Filter Search Input
    if(this.searchInput.length > 1) {
    // Simple search using a stringified object
      filtered = filtered.filter(vo => {
          return JSON.stringify(vo).toUpperCase().includes(this.searchInput.toUpperCase())
      })
    }
    this.filteredViewObjects.next(filtered)
    
    // Call Super for Sorting and Paginating
    super.filterSortAndPaginate(column, as)

  }

  /**
   * This method will update the ServiceRequests status
   * @param ServiceRequests 
   */
  statusSelectionChanged(vo: ViewObject, status: string) {
    let serviceRequest = vo.serviceRequest
    if (!serviceRequest || !status) {
      return;
    }
    
    // Change status
    var currentStatus = serviceRequest.status + "";
    serviceRequest.status = status;

    this.repositoryService.update('serviceRequest', serviceRequest).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het updaten van de status van serviceverzoek ${serviceRequest?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het updaten van de status van serviceverzoek ${serviceRequest?.name} is mislukt!`);
      serviceRequest.status = currentStatus;
    });
  }

  /**
   * This method will remove a ServiceRequest or show a cofirmation button
   * @param serviceRequest 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeServiceRequest(vo: ViewObject, index: number, confirmationNeeded: boolean) {
    let serviceRequest = vo.serviceRequest
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("confirm-remove" + index);
      var cancelButton = document.getElementById("cancel-remove" + index);
      var removeButton = document.getElementById("remove" + index);
      var editButton = document.getElementById("edit" + index);

      if (editButton) {
        editButton.style.display = "none";
      }

      if (confirmButton && removeButton && cancelButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
      }
      return;
    }

    // Remove ServiceRequest from the database
    this.repositoryService.delete('serviceRequest', serviceRequest).then(() => {
      // Remove Windows
      if(vo.windows){
        Promise.all(vo.windows.map(w => {
          return this.repositoryService.delete('window', w)
        }));
      }

      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van serviceverzoek ${serviceRequest?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van serviceverzoek ${serviceRequest?.name} is mislukt!`);
    });
  }

  /**
   * This methow will cancel a remove action
   * @param index 
   */
  cancelRemoval(index: number) {
    var confirmButton = document.getElementById("confirm-remove" + index);
    var cancelButton = document.getElementById("cancel-remove" + index);
    var removeButton = document.getElementById("remove" + index);
    var editButton = document.getElementById("edit" + index);

    if (confirmButton && removeButton && cancelButton && editButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "block";
      editButton.style.display = "block";
    }
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.serviceRequestStatus.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will return the icon resource based on the status
  */
  getStatusIcon(type: string) {
    if (!type) {
      return;
    }

    var statusResource = this.serviceRequestStatus.filter(s => s.value == type);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This method will set the ServiceRequest id to the given id so that the other status line could be accessed
   * @param serviceRequestId 
   */
  onStatusExpanderClick(serviceRequestId: string | undefined) {
    this.communicationService.openStatusServiceRequestIdChanged = true;
    if (this.communicationService.openStatusServiceRequestId == serviceRequestId) {
      this.communicationService.openStatusServiceRequestId = undefined;
    }
    this.communicationService.openStatusServiceRequestId = serviceRequestId;
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
  onClickLostFocus() {
    if (this.communicationService.openStatusServiceRequestIdChanged) {
      this.communicationService.openStatusServiceRequestIdChanged = false;
      return;
    }

    this.communicationService.openStatusServiceRequestIdChanged = false;
    this.communicationService.openStatusServiceRequestId = undefined;
  }

}
