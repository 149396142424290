import { Models } from "appwrite"
import { Department } from "../departmentEnum"

// This file keeps track of DB Collections and the DB Models.
// Access the DB Through the RepositoryService.

export type DBConfig = {
    [db in keyof DBT]: {
        id: string
        collections: {
        [coll in keyof DBT[db]]: string
        }
    }
}

export type Relationship<DB extends keyof DBT, COLL extends keyof DBT[DB]> = Table<DB, COLL> & string
export type Table<DB extends keyof DBT, COLL extends keyof DBT[DB]> = DBT[DB][COLL] & Models.Document;

export type DBT = {
    default: {
      serviceRequest: {
        name: string
        status: string
        type: string
        orderId: string
        machineId: string
        createdDate: string //Deprecated by $createdAt?
        startTime: string
        endTime: string
        requestSummary: string
        createdBy: string
        internalNotice: string
        referencedMachineId: string
        contactPersonIds: string
      }
      logEntry: {
        by: string
        message: string
      }
      WVBTask: {
        name: string
        status: string
        deadline: string
        serviceRequestId: string
        description: string
        department: Department
        archived: boolean
        logEntries: Relationship<"default", "logEntry">[]
      }
      MPTask: {
        name: string
        status: string
        deadline: string
        machineProjectId: string
        description: string
        archived: boolean
        triggerDate: string
        logEntries: Relationship<"default", "logEntry">[]
        createdBy: string
      }
      machineProject: {
        projectIdentifier?: string;
        type: string,
        status: string,
        createdBy: string,
        startDate?: Date,
        deadline?: Date,
        state: string,
        description: string
        referencedConstructors?: string,
        projectManager?: string,
        projectEngineer?: string,
        referencedMachines: Relationship<"default", "machine">[]
        logEntries: Relationship<"default", "logEntry">[]
      }
      window: {
        startTime: string
        endTime: string
        assignedResourceIds: string
        serviceRequestId: string
      }
      machine: {
        machineId: string
        name: string
        relatedCompanyId: string
        streetName: string
        zipCode: string
        city: string
        houseNumber: string
        country: string
      }
      company: {
        customerRelationId: string
        companyName: string
      }
      machineConstructionTask: {
        name: string
        status: string,
        state: string
        deadline?: Date
        machineProject?: Relationship<"default", "machineProject">
        description?: string
        department?: Department
        archived: boolean
        logEntries?: Relationship<"default", "logEntry">[]
        referencedDocuments?: string;
        createdBy?: string;
      }
    }
}

export const DB: DBConfig = {
  default: {
    id: "default",
    collections: {
      serviceRequest: "ServiceRequests",
      logEntry: "LogEntries",
      WVBTask: "WVBTask",
      MPTask: "MPTasks",
      machineProject: "MachineProjects",
      window: "Windows",
      machine: "Machines",
      company: "Companies",
      machineConstructionTask: "MachineConstructionTasks"
    }
  }
}