@if(serviceRequest){
    <div class="row create-request">
        <div class="col card">
           <div class="card-body">
              <h4>Serviceverzoek wijzigen</h4>
              <span class="meta-data">Aangemaakt door: <strong>{{serviceRequest.createdBy}}</strong> - Aangemaakt op: <strong>{{serviceRequest.createdDate | date: 'dd-MM-yyyy - HH:mm'}}</strong></span>
              <div class="row input-row">
                <div class="col">
                    <h5><i class="bi bi-1-circle"></i> Opdrachtgegevens</h5>
                    <p>Voer in onderstaande velden alle gegevens in die betrekking hebben tot het aan te maken serviceverzoek.</p>
                    <nav class="nav-tabs-wrapper">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <button class="nav-link active" id="nav-request-details-tab" data-bs-toggle="tab" data-bs-target="#nav-request-details" type="button" role="tab" aria-controls="nav-request-details" aria-selected="true">Opdrachtgegevens</button>
                          <button class="nav-link" id="nav-request-summary-tab" data-bs-toggle="tab" data-bs-target="#nav-request-summary" type="button" role="tab" aria-controls="nav-request-summary" aria-selected="false">Opdrachtomschrijving</button>
                          <button class="nav-link" id="nav-internal-summary-tab" data-bs-toggle="tab" data-bs-target="#nav-internal-summary" type="button" role="tab" aria-controls="nav-internal-summary" aria-selected="false">Interne opmerkingen</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-request-details" role="tabpanel" aria-labelledby="nav-request-details-tab">
                            <div class="row">
                                <div class="form-group col">
                                    <label for="inputMachineId">Machinenummer: 
                                        @if(serviceRequest.referencedMachine){
                                            <i class="bi bi-link referenced"></i>
                                        }
                                    </label>
                                    <input type="text" class="form-control" id="inputMachineId" placeholder="Machinenummer..."  [(ngModel)]="serviceRequest.machineId" (ngModelChange)="machineNumberInputChanged()">
                                </div>
                                <div class="form-group col">
                                    <label for="inputOrderId">Ordernummer:</label>
                                    <input type="text" class="form-control" id="inputOrderId" placeholder="Ordernummer..."  [(ngModel)]="serviceRequest.orderId">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputName">Omschrijving:</label>
                                <input type="text" class="form-control" id="inputName" placeholder="Naam serviceverzoek..."  [(ngModel)]="serviceRequest.name">
                            </div>
                            <div class="row">
                                <div class="form-group col">
                                    <label for="serviceRequestTypeInput">Type:</label>
                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="{{serviceRequestService.getTypeStatusIcon(serviceRequest.type ?? '')}}"></i></div>
                                          </div>
                                          <select [(ngModel)]="serviceRequest.type" class="form-select" name="serviceRequestTypeDropdown" id="serviceRequestTypeInput">
                                            @for(typeItem of serviceRequestService.serviceRequestTypes; track typeItem.value)  {
                                                <option value="{{typeItem.value}}">
                                                    {{typeItem.description}} 
                                                </option>
                                            }
                                          </select>
                                      </div>
                                </div>
                                <div class="form-group col">
                                    <label for="serviceRequestStatusInput">Status:</label>
                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="{{serviceRequestService.getStatusIcon(serviceRequest.status)}}"></i></div>
                                          </div>
                                          <select [(ngModel)]="serviceRequest.status" class="form-select" name="serviceRequestTypeDropdown" id="serviceRequestStatusInput">
                                            @for(typeItem of serviceRequestService.serviceRequestStatus; track typeItem.value){
                                                <option value="{{typeItem.value}}">
                                                    {{typeItem.description}} 
                                                </option>
                                            }  
                                          </select>
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col">
                                    <label for="inputStartDate">Startdatum:</label>
                                    <input type="datetime-local" max="9999-12-31T23:59" class="form-control" id="inputStartDate" [ngModel]="serviceRequest.startTime | date:'yyyy-MM-dd HH:mm'" (ngModelChange)="onStartTimeValuesChanged($event)">
                                </div>
                                <div class="form-group col">
                                    <label for="inputEndDate">Einddatum:</label>
                                    <input type="datetime-local" max="9999-12-31T23:59" class="form-control" id="inputEndDate" [ngModel]="serviceRequest.endTime | date:'yyyy-MM-dd HH:mm'" (ngModelChange)="onEndTimeValuesChanged($event)">
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-internal-summary" role="tabpanel" aria-labelledby="nav-internal-summary-tab">
                            <div class="form-group col">
                                <label>Interne opmerkingen:</label>
                                <textarea class="form-control request-summary" maxlength="1000" rows="9" [(ngModel)]="serviceRequest.internalNotice"></textarea>
                                <div class="char-counter">
                                    @if(serviceRequest.internalNotice){
                                        <div class="length-warning">
                                            @if(serviceRequest.internalNotice.length >= 1000){
                                                <i class="bi bi-exclamation-triangle-fill"></i>
                                                <small>Het maximaal aantal tekens is 1000</small>
                                            }
                                        </div>
                                        <small [ngClass]="serviceRequest.internalNotice.length >= 1000 ? 'exceeded' : 'counter'">{{serviceRequest.internalNotice.length}}/1000</small>
                                    } @else {
                                        <small>0/1000</small>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-request-summary" role="tabpanel" aria-labelledby="nav-request-summary-tab">
                            <div class="form-group col">
                                <label>Samenvatting:</label>
                                <textarea class="form-control request-summary" maxlength="1000" rows="9" [(ngModel)]="serviceRequest.requestSummary"></textarea>
                                <div class="char-counter">
                                    @if(serviceRequest.requestSummary){
                                        <div class="length-warning">
                                            @if(serviceRequest.requestSummary.length >= 1000){
                                                <i class="bi bi-exclamation-triangle-fill"></i>
                                                <small>Het maximaal aantal tekens is 1000</small>
                                            }
                                        </div>
                                        <small [ngClass]="serviceRequest.requestSummary.length >= 1500 ? 'exceeded' : 'counter'">{{serviceRequest.requestSummary.length}}/1000</small>
                                    } @else {
                                        <small>0/1500</small>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h5><i class="bi bi-2-circle"></i> Klant & Machine</h5>
                    <p>Onderstaand component geeft een samenvatting van de automatisch gekoppelde gegevens en de mogelijkheid tot het koppelen van contactpersonen.</p>
                    <nav class="nav-tabs-wrapper">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <button class="nav-link active" id="nav-company-tab" data-bs-toggle="tab" data-bs-target="#nav-company" type="button" role="tab" aria-controls="nav-company" aria-selected="true">Klant & Machine</button>
                          <button class="nav-link" id="nav-contact-person-tab" data-bs-toggle="tab" data-bs-target="#nav-contact-person" type="button" role="tab" aria-controls="nav-contact-person" aria-selected="false">Contactpersoon</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-company" role="tabpanel" aria-labelledby="nav-company-tab">
                            <app-service-request-customer-values [serviceRequest]="serviceRequest" [machine]="serviceRequest.referencedMachine"></app-service-request-customer-values>
                        </div>
                        <div class="tab-pane fade" id="nav-contact-person" role="tabpanel" aria-labelledby="nav-contact-person-tab">
                            <app-service-request-contact-persons [serviceRequest]="serviceRequest"></app-service-request-contact-persons>
                        </div>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                    <hr>
                </div>
              </div>
              <div class="row">
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <h5 class="details-header"><i class="bi bi-3-circle"></i> WVB-taken</h5>
                    </div>
                    @if(showWVB){
                        <button class="btn btn-secondary btn-sm collapse-button" (click)="showWVB = !showWVB"><i class="bi bi-arrow-up-square"></i> Inklappen</button>
                    } @else {
                        <button class="btn btn-secondary btn-sm collapse-button" (click)="showWVB = !showWVB"><i class="bi bi-arrow-down-square"></i> Uitklappen</button>
                    }
                </div>
                @if(showWVB){
                    <app-work-preparation-tasks-table [viewOnly]="true"></app-work-preparation-tasks-table>
                }

              </div>
              <hr>

              <div class="row">
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <h5 class="details-header"><i class="bi bi-4-circle"></i> Rooster &amp; Transfer/Hotel</h5>
                    </div>
                    @if(showScheduleAndTransfers){
                        <button class="btn btn-secondary btn-sm collapse-button" (click)="showScheduleAndTransfers = !showScheduleAndTransfers"><i class="bi bi-arrow-up-square"></i> Inklappen</button>
                    } @else {
                        <button class="btn btn-secondary btn-sm collapse-button" (click)="showScheduleAndTransfers = !showScheduleAndTransfers"><i class="bi bi-arrow-down-square"></i> Uitklappen</button>
                    }
                </div>
                <p class="mt-2">Beheer in onderstaand component het rooster behorend bij deze inzet. Daarnaast kunnen in dit component eventuele reis onderdelen worden toegevoegd zoals; overnachtingen en transfers</p>
                @if(showScheduleAndTransfers){
                    <nav class="nav-tabs-wrapper">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <button class="nav-link active" id="nav-schedule-tab" data-bs-toggle="tab" data-bs-target="#nav-schedule" type="button" role="tab" aria-controls="nav-schedule" aria-selected="true">Rooster</button>
                          <button class="nav-link" id="nav-travel-tab" data-bs-toggle="tab" data-bs-target="#nav-travel" type="button" role="tab" aria-controls="nav-travel" aria-selected="false">Transfer/Hotel</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-schedule" role="tabpanel" aria-labelledby="nav-schedule-tab">
                            <div class="schedule">
                                <app-service-request-schedule [serviceRequest]="serviceRequest" [readOnly]="false"></app-service-request-schedule>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-travel" role="tabpanel" aria-labelledby="nav-travel-tab">
                            <div class="schedule">
                                <app-travel-components-list> </app-travel-components-list>
                            </div>
                        </div>
                    </div>
                }
              </div>
              <div class="row second-sub-row">
                <div class="col actions">
                    <button class="btn btn-secondary close-operation" (click)="close()"><i class="bi bi-x-lg"></i>Sluiten</button>
                    <button class="btn btn-success" (click)="updateServiceRequest()"><i class="bi bi-save"></i>Serviceverzoek updaten</button>
                </div>
              </div>
           </div>
        </div>
    </div>
}