@if(activeContactPerson){
    <div class="row">
        <div class="col adjust-create-contact-person">
            <div class="row">
                <div class="form-group col">
                    <label for="inputFirstname">Voornaam:</label>
                    <input type="text" class="form-control" id="inputFirstname" [(ngModel)]="activeContactPerson.firstname">
                </div>
                <div class="form-group col">
                    <label for="inputLastname">Achternaam:</label>
                    <input type="text" class="form-control" id="inputLastname" [(ngModel)]="activeContactPerson.lastname">
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label for="inputPhoneNumber">Vast telefoonnummer:</label>
                    <input type="text" class="form-control" id="inputPhoneNumber" [(ngModel)]="activeContactPerson.landlineNumber">
                </div>
                <div class="form-group col">
                    <label for="inputPhoneNumber">Mobiel telefoonnummer:</label>
                    <input type="text" class="form-control" id="inputPhoneNumber" [(ngModel)]="activeContactPerson.phoneNumber">
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label for="inputEmailAdress">E-mailadres:</label>
                    <input type="email" class="form-control" id="inputEmailAdress" [(ngModel)]="activeContactPerson.emailAdress">
                </div>
                <div class="form-group col">
                    <label for="contactPersonRoleSelect">Rol:</label>
                    <select class="form-select" id="contactPersonRoleSelect" [(ngModel)]="activeContactPerson.role">
                      @for(role of roles; track role.id){
                        <option value="{{role.value}}">
                            {{role.description}}
                        </option>
                      }
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col actions-adjust-create">
                    <button class="btn btn-secondary" (click)="close()"><i class="bi bi-x-lg"></i></button>
                    @if(activeContactPerson.$id){
                        <button class="btn btn-success" (click)="saveChanges()"><i class="bi bi-plus-square"></i>Wijzigingen opslaan</button>
                        <button class="btn btn-danger" (click)="remove()"> <i class="bi bi-trash"></i></button>
                    } @else {
                        <button class="btn btn-success" (click)="saveChanges()"><i class="bi bi-plus-square"></i>Contactpersoon aanmaken</button>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    @if(!serviceRequest?.referencedMachine?.company?.$id){
        <div class="row alert-missing-company">
            <div class="col">
                <div class="alert alert-warning required-input" role="alert">
                    <span><i class="bi bi-info-circle"></i> Koppel eerst een <strong>klant</strong> om contactpersonen te kunnen toewijzen</span>
                  </div>
            </div>
        </div>
    }
    @if(feedbackMessage){
        <div class="row alert-missing-company">
            <div class="col">
                <div class="alert alert-warning required-input" role="alert">
                    <span><i class="bi bi-info-circle"></i> {{feedbackMessage}}</span>
                  </div>
            </div>
        </div>
    }
    <div class="rowmt-2 contact-persons-wrapper">
        <div class="col form-group contact-persons-group">
            <label for="contactPersonsSelect">Contactpersonen:</label>
            <select multiple class="form-control" id="contactPersonsSelect" [(ngModel)]="selectedContactPersons" [disabled]="!serviceRequest?.referencedMachine?.company?.$id">
              @for(contactPerson of serviceRequest?.referencedMachine?.company?.contactPersons; track contactPerson.$id){
                <option [class]="attachedToServiceRequest(contactPerson.$id) ? 'attached' : 'detached'" value="{{contactPerson.$id}}">
                    {{contactPerson.firstname}} {{contactPerson.lastname}} - {{contactPerson.role}}
                </option>
              }
            </select>
            @if(!serviceRequest?.referencedMachine?.company?.contactPersons || serviceRequest?.referencedMachine?.company?.contactPersons?.length == 0){
                <div class="no-contact-persons-wrapper">
                    <span class="no-contact-persons">Geen contactpersonen bekend. <br> Maak een contactpersoon aan middels de <i class="bi bi-plus-square"></i> knop</span>
                </div>
            }
        </div>
    </div>
    <div class="row">
        <div class="col new-adjust-action">
            <button class="btn btn-secondary" [disabled]="!serviceRequest?.referencedMachine?.company?.$id" (click)="newContactPerson()"><i class="bi bi-plus-square"></i></button>
            <button class="btn btn-secondary" [disabled]="!serviceRequest?.referencedMachine?.company?.$id || (this.selectedContactPersons?.length ?? 0) == 0" (click)="editContactPerson()"><i class="bi bi-pencil"></i></button>
        </div>
        <div class="col add-remove-action">
            <button class="btn btn-danger" [disabled]="!serviceRequest?.referencedMachine?.company?.$id || (this.selectedContactPersons?.length ?? 0) == 0" (click)="dettachToServiceRequest()"><i class="bi bi-person-x"></i></button>
            <button class="btn btn-success" [disabled]="!serviceRequest?.referencedMachine?.company?.$id || (this.selectedContactPersons?.length ?? 0) == 0" (click)="attachToServiceRequest()"><i class="bi bi-person-fill-add"></i></button>
        </div>
    </div>
}