import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Company } from 'src/app/models/company';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  companyTable: string = "Companies"

  constructor() {
  }

    /**
    * This method will retrieve a Company with the current relationId
    * @param relationId 
    * @returns 
    */
    async getCompany(relationId: string) : Promise<Company | undefined> {
      return await Api.database.getDocument<Company>(environment.database, this.companyTable, relationId);
    }

    /**
    * This method will retrieve a Company with the given companyName
    * @param companyName 
    * @returns 
    */
    async getCompanyByName(companyName: string) : Promise<Company | undefined> {
      var companies = await Api.database.listDocuments<Company>(environment.database, this.companyTable, [Query.limit(50), Query.equal('companyName', companyName)]);
    
      if(companies?.documents?.length > 0){
        return companies.documents[0];
      }

      return undefined;
    }

   /**
    * This method will retrieve all Companies of the current team
    * @param resourceId 
    * @returns 
    */
   async getCompanies(){
    var resultCount = 50;
    var resultList: Company[] = [];
    var lastId: string | undefined = undefined;
    var resultSet: Models.DocumentList<Company>;

    while (resultCount == 50) {
      if(lastId){
        resultSet = await Api.database.listDocuments<Company>(
          environment.database,
          this.companyTable,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        resultSet = await Api.database.listDocuments<Company>(
          environment.database,
          this.companyTable,
          [Query.limit(50)]
        );
      }

      lastId = resultSet?.documents[resultSet.documents.length - 1]?.$id;
      resultList.push(...resultSet.documents);
      resultCount = resultSet.documents.length;
    }

    return resultList;
   }

  /**
  * This method will create a new Company in the database
  */
  addCompany(company: Company) {
    return Api.database.createDocument(environment.database, this.companyTable, ID.unique(), this.createPartialCompany(company))
  }

  /**
   * This method will update an existing Company in the Database
   */
  updateCompany(company: Company) {
    return Api.database.updateDocument(environment.database, this.companyTable, company.$id, this.createPartialCompany(company));
  }

  /**
   * This method will delete an existing Company from the database
   */
  deleteCompany(company: Company) {
    return Api.database.deleteDocument(environment.database, this.companyTable, company.$id);
  }

  /**
   * Creates a partial instance of the Company without AppWrite properties
   * @param vehicle 
   * @returns 
   */
  private createPartialCompany(company: Company): Partial<Company> {
    if (!company) {
      return {};
    }

    return {
      customerRelationId: company.customerRelationId.toString(),
      companyName: company.companyName
    };
  }
}
