<div class="container-fluid p0 sign-in-component">
    <div class="row-section">
        <div class="col brand-side">
            <div class="blur">

            </div>
        </div>
        <div class="col-4 sign-in-wrapper container-fluid p0">
            <div class="brand-wrapper row">
                <img src="assets/unikon-logo-fullcolor.svg">
            </div>
            <div class="row">
                <div class="col flex-center">

                    <p>Deze functionaliteit is in ontwikkeling.
                        Neem contact op met een beheerder om uw wachtwoord aan te passen.
                    </p>

                    <a href="#/">Terug</a>
                    <!-- <form class="col-12 login-form" [formGroup]="form" (ngSubmit)="recoverPassword()">
                        <div class="form-group form-wrapper">
                            <label for="email-adress">E-mailadres</label>
                            <input required type="email" formControlName="email" #email class="form-control" id="email-adress" placeholder="Uw E-mailadres">
                            <small *ngIf="showEmailWarning" id="emailHelp" class="form-text text-muted">{{emailWarningMessage}}</small>
                        </div>
                        <button type="submit" class="btn btn-primary login-button">Wachtwoord herstellen</button>
                        <div class="extra-actions">
                            <button class="btn btn-primary" routerLink="/register">Registreren</button>
                            <button class="btn btn-primary primary-action" routerLink="/sign-in">Inloggen</button>
                        </div>
                    </form> -->

                </div>
            </div>
        </div>
    </div>
</div>
<div class="row message-pop-up">
    <div class="success-warning message" *ngIf="successFeedback">
        <div class="alert alert-success" role="alert">
            <strong>Succes!</strong> {{successFeedback}}
        </div>
    </div>
</div>