import { Component, ElementRef, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoggingService } from "src/app/services/logging/logging.service";
import { Base } from "./base.component";

  
@Component({
    selector: 'base-table',
    templateUrl: './base-table.component.html',
    styleUrls: ['./base-table.component.scss']
  })
  
export class BaseTable<ViewObject> extends Base {
    loadingTable: boolean = true;

    // FilterSortPaginate Related
    sortedBy: string;
    sortedAs: 'string'|'number'|'Date';
    sortedAscending?: boolean;
    perPage: number;
    activePage: number;
    pageNumbers: number[];

    localStorageKeyPrefix: string

    // The Filled In ViewObjects, with subscriptions to their parent collections
    // Created by the createViewableObjects function
    allViewObjects: BehaviorSubject<ViewObject[]> = new BehaviorSubject([] as ViewObject[]);
    filteredViewObjects: BehaviorSubject<ViewObject[]> = new BehaviorSubject([] as ViewObject[]);
    currentView: ViewObject[] = [];

    constructor(private element: ElementRef, @Inject("localStorageKeyPrefix") localStorageKeyPrefix: string,
    private baseLogger: LoggingService) { 
        super(element);

        this.localStorageKeyPrefix = localStorageKeyPrefix

        this.perPage = Number(localStorage.getItem(localStorageKeyPrefix + "_PERPAGE") ?? 20);
        this.activePage = 0;
        this.pageNumbers = [0]
        this.sortedAscending = true
        this.sortedBy = '$id'
        this.sortedAs = 'string'

        let storedSortedBy = localStorage.getItem(localStorageKeyPrefix+"_SORTEDBY")?.split('_') ?? [];
        if(storedSortedBy.length == 3){
            this.sortedBy = storedSortedBy[0];
            this.sortedAs = <'string'|'number'|'Date'>storedSortedBy[1];
            this.sortedAscending = storedSortedBy[2] == "true";
        }
        
    }

    createViewableObjects(viewObjects?: ViewObject[]) {
        if(!viewObjects) {
            return
        }

        this.allViewObjects.next(viewObjects)
        this.filteredViewObjects.next(JSON.parse(JSON.stringify(viewObjects))) //Deep Copy

        this.filterSortAndPaginate(this.sortedBy, this.sortedAs)
        
        this.baseLogger.debug("Finished creating viewable objects", 'BaseTable')
        this.loadingTable = false;
    }

    /**
     * Private function to call, for view: ViewSort
     */
    filterSortAndPaginate(column: string, as: 'number'|'string'|'Date'){
        
        // 2: Sort
        this.filteredViewObjects.next(
            this.sort<ViewObject>(this.filteredViewObjects.value, column, as, this.sortedAscending)
        )
        this.pageNumbers = Array.from({ length: Math.ceil(this.filteredViewObjects.value.length / this.perPage) }, (value, index) => index);

        // 3: Paginate
        this.currentView = this.getPage<ViewObject>(this.filteredViewObjects.value, this.activePage, this.perPage)
        // Store sort order
        localStorage.setItem(this.localStorageKeyPrefix + '_SORTEDBY', `${this.sortedBy.toString()}_${this.sortedAs}_${this.sortedAscending}`);
    }

    /**
     * Call in View to handle perPage Change
     */
    onPerPageChanged(){
        localStorage.setItem(this.localStorageKeyPrefix + "_PERPAGE", this.perPage?.toString());
        this.activePage = 0 //Reset page to prevent ghost page
        this.pageNumbers = Array.from({ length: Math.ceil(this.filteredViewObjects.value.length / this.perPage) }, (value, index) => index);
        this.currentView = this.getPage<ViewObject>(this.filteredViewObjects.value, this.activePage, this.perPage)
    }

    /**
     * Call in View to handle pagination page change
     */
    viewChangePage(newPage: number) {
        this.activePage = newPage
        this.currentView = this.getPage<ViewObject>(this.filteredViewObjects.value, this.activePage, this.perPage)
    }

    /**
     * Call in View to handle click action for sorting
     */
    viewSort(column: string, as: 'number'|'string'|'Date'){
        if(this.sortedBy == column) {
            this.sortedAscending = !this.sortedAscending
        } else {
            this.sortedAscending = true
            this.sortedBy = column
        }
        this.filterSortAndPaginate(column, as)
    }

    /**
    * Call in View after any Filter change
    */
    viewFilter() {
      this.filterSortAndPaginate(this.sortedBy, this.sortedAs)
    }

    protected deepGet = (obj: any, keys: any) => keys.reduce((xs: any, x: any) => xs?.[x] ?? null, obj);

    protected deepGetByPaths = (obj:any, ...paths:any): string[] =>
    paths.map((path: string) =>
        this.deepGet(
        obj,
        path
            .replace(/\[([^\[\]]*)\]/g, '.$1.')
            .split('.')
            .filter(t => t !== '')
        )
    );

    /**
     * The logical Sort function. Do not call directly form View!
     */
    private sort<T>(documents: T[], field: string, as: "string" | "number" | "Date",
        asc: boolean = true) {
        // Note: column is string because of Type Checking Problems so we check at runtime
        if(!this.deepGetByPaths(this.filteredViewObjects.value[0], field)[0]) {
            this.baseLogger.error("FilterSortPaginate: Column " + field + " unkown", 'BaseTable')
            return documents
        }

        if(documents.length < 1) {
          this.baseLogger.error("Error Sorting: Empty Documents", 'BaseTable')
          return documents
        }

        const toSortValue = this.deepGetByPaths(documents[0], field)[0]
        if(toSortValue == undefined) {
          this.baseLogger.error("Error Sorting: Field " + field.toString() + " Undefined", 'BaseTable')
          return documents
        }
    
        documents.sort((a: T, b: T) => {
            let fA = (this.deepGetByPaths(a, field)[0] || '0').toString()
            let fB = (this.deepGetByPaths(b, field)[0] || '0').toString()
          switch (as) {
            case 'string':
              let tA = fA.toUpperCase()
              let tB = fB.toUpperCase()
              if (asc) {
                  return  (tB > tB) ? -1 : (tB < tA) ? 1 : 0
                } else {
                  return  (tA > tB) ? -1 : (tA < tB) ? 1 : 0
              }
            case 'number':
              if (asc) {
                return parseInt(fA) - parseInt(fB)
              } else {
                return parseInt(fB) - parseInt(fA)
              }
            case 'Date':
              if (asc) {
                return new Date(fA).getTime() - new Date(fB).getTime()
              } else {
                return new Date(fB).getTime() - new Date(fA).getTime()
              }
          
            default:
              return 0
          }
        })
        this.baseLogger.debug("Sorted " + field.toString() + " " + (asc? "asc" : "desc"), 'BaseTable')
        return documents
      }

    /**
     * The logical getPage function. Do not call directly form View!
     */
    private getPage<T>(documents: T[], activePage: number, limit: number) {
        return documents.slice(activePage * limit, (activePage + 1)* limit);
    }

    
}
